import React, { useContext, useEffect } from 'react';

import { AppContext } from '../context/AppContext';

import AppMenu from './AppMenu';
import Dashboard from './Dashboard';
import AppStatistics from './AppStatistics';
import Campaigns from './Campaigns';
import AppCredit from './AppCredit';
import AppSettings from './AppSettings';
import AppHelp from './AppHelp';
import AppContactUs from './AppContactUs';

const AppContent = () => {
  const { appContentType, setAppContentType } = useContext(AppContext);

  return (
    <main className="content content--app">
      <section className="container">
        <AppMenu />
        {appContentType === 'dashboard' && <Dashboard />}
        {appContentType === "statistics" && <AppStatistics />}
        {appContentType === 'campaigns' && <Campaigns />}
        {appContentType === "credit" && <AppCredit />}
        {appContentType === "settings" && <AppSettings />}
        {appContentType === "help" && <AppHelp />}
        {appContentType === "contact-us" && <AppContactUs />}
      </section>
    </main>
  );
};

export default AppContent;
