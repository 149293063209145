// src/store/reducers/companyDetailsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../components/AxiosConfig';

export const updateCompanyDetails = createAsyncThunk(
  'companyDetails/updateCompanyDetails',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`shop/update/${id}/`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCompanyInfo = createAsyncThunk(
  "companyDetails/getCompanyInfo",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`shop/detail/${id}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const companyDetailsSlice = createSlice({
  name: 'companyDetails',
  initialState: {
    name: '',
    street: '',
    city: '',
    postal_code: '',
    ico: '',
    dic: '',
    email: '',
    country_billing: 'cs',
    loading: false,
    error: null
  },
  reducers: {
    updateForm: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    updateCountryBilling: (state, action) => {
      state.country_billing = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCompanyDetails.fulfilled, (state, action) => {
        // Handle successful update
      })
      .addCase(updateCompanyDetails.rejected, (state, action) => {
        // Handle failed update
      })
      .addCase(getCompanyInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCompanyInfo.fulfilled, (state, action) => {
        const { name, street, city, postal_code, ico, dic, email, country_billing } = action.payload;
        state.name = name;
        state.street = street;
        state.city = city;
        state.postal_code = postal_code;
        state.ico = ico;
        state.dic = dic;
        state.email = email;
        state.country_billing = country_billing;
        state.loading = false;
      })
      .addCase(getCompanyInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { updateForm, updateCountryBilling } = companyDetailsSlice.actions;
export default companyDetailsSlice.reducer;
