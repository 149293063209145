// src/store/reducers/uploadProductsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../components/AxiosConfig';

export const updateUploadProducts = createAsyncThunk('uploadProducts/updateUploadProducts', async ({ id, data }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('https://permoniq.com/api/validate-xml-feed/', data);
    console.log(response)
    if (response.data.status === true) {
      console.log("Platný feed!")
      console.log(data);
      const secondResponse = await axiosInstance.put(`shop/update/${id}/`, data);
      console.log(secondResponse);
      return secondResponse.data;
    } else {
      console.log("Neplatný feed!");
    }
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getFeed = createAsyncThunk(
  "getFeed/updateGetFeed",
  async (id, {rejectWithValue}) => {
    try {
      const response = await axiosInstance.get(`shop/detail/${id}/`)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const initialState = {
  feed: '',
};

const uploadProductsSlice = createSlice({
  name: 'uploadProducts',
  initialState,
  reducers: {
    updateForm(state, action) {
      state[action.payload.field] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUploadProducts.fulfilled, (state, action) => {
      // Handle successful update
    });
    builder.addCase(updateUploadProducts.rejected, (state, action) => {
      // Handle failed update
    });
  }
});

export const { updateForm } = uploadProductsSlice.actions;
export default uploadProductsSlice.reducer;
