import { createSlice } from '@reduxjs/toolkit';

const pkSlice = createSlice({
  name: 'pk',
  initialState: '',
  reducers: {
    setPk: (state, action) => action.payload,
  },
});

export const { setPk } = pkSlice.actions;
export default pkSlice.reducer;
