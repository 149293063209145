import React, { useState, useRef, useContext, useEffect } from 'react';

import { AppContext } from '../context/AppContext';
import axiosInstance from './AxiosConfig';
import useUserDetails from '../hooks/useUserDetails';

import LoadingModal from './LoadingModal';
import SimpleAlert from './SimpleAlert';

const AppSettings = () => {
    const [shop, setShop] = useState([]);
    const [country, setCountry] = useState('cs');
    const [alertMessage, setAlertMessage] = useState('');
    const [codeVisible, setCodeVisible] = useState(false);
    const [rangeValue, setRangeValue] = useState(20);
    const [productFeedUrl, setProductFeedUrl] = useState('');
    const [limitValue, setLimitValue] = useState(100);
    const [managerEmail, setManagerEmail] = useState('priklad@email.com');
    const [userList, setUserList] = useState([
        'martin.skocdopole@gmail.com',
        'jan.novak@email.cz'
    ]);
    const [feedUrlError, setFeedUrlError] = useState('');

    const copyButtonRef = useRef(null);

    const {
        code,
        setCode,
        userData,
        selectedShop,
        pno,
        setPno,
        shopName,
        merchantId,
        isUserDataLoading,
        setIsUserDataLoading,
        campaignId,
        budget,
        setBudget
    } = useContext(AppContext);

    useEffect(() => {
        setProductFeedUrl(selectedShop ? selectedShop.feed : userData.shops[0].feed)
    }, [userData, selectedShop])

    useEffect(() => {
        setRangeValue(pno)
    }, [pno,])

    const validateUrl = (url) => {
        const urlPattern = /^https?:\/\/.*\..*/i;
        return urlPattern.test(url);
    };

    useEffect(() => {
        setLimitValue(budget)
    }, [budget])

    useUserDetails(setShop, setCountry, "settings");


    const handleCodeCopy = () => {
        if (codeVisible) {
            navigator.clipboard.writeText(code).then(() => {
                alert('Kód zkopírován do schránky!');
                copyButtonRef.current.blur();
            });
        }
    };

    const handleRangeChange = (e) => {
        setRangeValue(e.target.value);
    };

    const handleFeedUrlChange = (e) => {
        const newUrl = e.target.value;
        setProductFeedUrl(newUrl);

        if (!validateUrl(newUrl)) {
            setFeedUrlError('Neplatná URL adresa. Zadejte prosím platnou URL začínající http:// nebo https://.');
        } else {
            setFeedUrlError('');
        }
    };

    const handleLimitChange = (e) => {
        if (e.target.value > 20000) {
            setAlertMessage("Limit nemůže být vyšší než 20 000 Kč")
            setLimitValue(20000)
        } else {
            setLimitValue(e.target.value);
        }
    };

    const handleManagerEmailChange = (e) => {
        setManagerEmail(e.target.value);
    };

    const handleUserRemove = (email) => {
        setUserList(userList.filter(user => user !== email));
    };

    const toggleCodeVisibility = () => {
        setCodeVisible(!codeVisible);
    };

    const validateFeed = async (event) => {
        event.preventDefault();

        // Validate URL before proceeding
        if (!validateUrl(productFeedUrl)) {
            setAlertMessage('Neplatná URL adresa. Zadejte prosím platnou URL začínající http:// nebo https://.')
            setFeedUrlError('Neplatná URL adresa. Zadejte prosím platnou URL začínající http:// nebo https://.');
            return;
        }

        try {
            setIsUserDataLoading(true)
            const response = await axiosInstance.post('validate-xml-feed/', { feed: productFeedUrl });

            if (response.data.status === true) {
                try {
                    setIsUserDataLoading(true)
                    const feedUpdateResponse = await axiosInstance.patch(`shop/update/${selectedShop ? selectedShop.uuid : userData.shops[0].uuid}/`, { feed: productFeedUrl });
                    console.log(feedUpdateResponse);
                    setIsUserDataLoading(false)
                } catch (error) {
                    console.log("Feed update error: ", error.response)
                    setIsUserDataLoading(false)
                }

                try {
                    setIsUserDataLoading(true)
                    const merchantUpdateResponse = await axiosInstance.patch("https://permoniq.com/api/googlemerchant/account/feed/update/", {
                        account_id: merchantId,
                        feed_name: shopName,
                        feed_url: productFeedUrl
                    })
                    isUserDataLoading(false)
                } catch (error) {
                    console.log("Merchant update error: ", error.response)
                    setIsUserDataLoading(false)
                }
            }
        } catch (error) {
            console.log(error.response);
            alert('Došlo k chybě při odesílání feedu.');
            setIsUserDataLoading(false)
        }
    };

    const updatePNO = async (event) => {
        event.preventDefault()
        event.target.blur()
        setIsUserDataLoading(true)
        try {
            const updatePNOResponse = await axiosInstance.patch(`shop/update/${selectedShop ? selectedShop.uuid : userData.shops[0].uuid}/`, { pno: rangeValue });
            console.log(updatePNOResponse)
            setPno(rangeValue)
            setIsUserDataLoading(false)

            try {
                const updateCampaignRoasResponse = await axiosInstance.patch(`campaign/${campaignId}/update/`, { roas: Number((100 / pno).toFixed(2)) })
                console.log(updateCampaignRoasResponse)
            } catch (error) {
                console.log(error.response)
            }
        } catch (error) {
            console.log("Update PNO Error: ", error.response)
            setIsUserDataLoading(false)
        }
    }

    const updateLimit = async (event) => {
        event.preventDefault()
        event.target.blur()
        setIsUserDataLoading(true)

        try {
            const updateLimitResponse = await axiosInstance.patch(`shop/update/${selectedShop ? selectedShop.uuid : userData.shops[0].uuid}/`, { budget: Number(Number(limitValue).toFixed(2)) });
            console.log(updateLimitResponse)

            try {
                const updateCampaignLimitResponse = await axiosInstance.patch(`campaign/${campaignId}/update/`, { budget: Number(Number(limitValue).toFixed(2)) })
                console.log(updateCampaignLimitResponse)
                setIsUserDataLoading(false)
            } catch (error) {
                console.log(error.response)
                setIsUserDataLoading(false)
            }
        } catch (error) {
            console.log(error.response)
            setIsUserDataLoading(false)
        }

        try {
            const updateCampaignLimitResponse = await axiosInstance.patch(`campaign/${campaignId}/update/`, { budget: Number(Number(limitValue).toFixed(2)) })
            console.log(updateCampaignLimitResponse)
            setIsUserDataLoading(false)
        } catch (error) {
            console.log(error.response)
            setIsUserDataLoading(false)
        }
    }

    const closeAlert = () => {
        setAlertMessage(''); // Vyčistí zprávu pro zavření alertu
    };

    const showAlert = (msg) => {
        setAlertMessage(msg);
    };

    return (
        <>
            {isUserDataLoading && <LoadingModal />}
            {alertMessage && <SimpleAlert message={alertMessage} onClose={closeAlert} />}
            <article className="app-content">
                <h1>Nastavení e-shopu</h1>

                <div className="app-content__row">
                    <div className="box">
                        <h2>PermoniQ</h2>
                        <p>Pro pokročilé měření a kvalitnější optimalizaci reklam prosím nainstalujte následující část kódu do vašeho <span className="nowrap">e-shopu</span>.</p>
                        <p>Kód vložte do hlavičky vašeho eshopu před koncový tag "<strong>&lt;/head&gt;</strong>"</p>
                        <div className={`snuggle form__hide ${codeVisible ? 'is-open' : ''}`}>
                            {codeVisible && (
                                <textarea name="code" id="code" className="form__code" cols="30" rows="4" value={code} onChange={(e) => setCode(e.target.value)} />
                            )}
                        </div>
                        <p className="snuggle">
                            <button className="btn js-copy" onClick={handleCodeCopy} ref={copyButtonRef} disabled={!codeVisible}>Kopírovat kód</button>
                            &nbsp;&nbsp;
                            <a href="#code" className="link js-form-show" onClick={toggleCodeVisibility}>
                                {codeVisible ? 'Skrýt kód' : 'Zobrazit kód'}
                            </a>
                        </p>
                        <p>Jestli se vám podařilo Tanganica pixel ve vašem <span className="nowrap">e-shopu</span> nastavit správně, ověříte stisknutím tlačítka&nbsp;Otestovat pixel.</p>
                    </div>

                    <div className="box">
                        <h2>Váš produktový XML feed</h2>
                        <form onSubmit={validateFeed} className="form">
                            <p><a href="#" className="link">Jak napojit feed?</a></p>
                            <p className="snuggle">Aktuální odkaz na feed: {productFeedUrl}</p>
                            <p>
                                Vložte URL adresu vašeho produktového XML feedu:<br />
                                <input type="text" value={productFeedUrl} onChange={handleFeedUrlChange} />
                            </p>
                            {feedUrlError && <p style={{ color: 'red' }}>{feedUrlError}</p>}
                            <p>Prosím vložte odkaz na váš produktový XML feed, a to ve formátu určeném pro Google Nákupy</p>
                            <p><button className="btn" disabled={feedUrlError}>Uložit</button></p>
                        </form>
                    </div>
                </div>

                <div className="app-content__row">
                    <div className="box">
                        <h2>Cílové PNO</h2>
                        <form action="#" method="post" className="form">
                            <p><strong>PNO: <span className="js-range-value">{rangeValue},00&nbsp;%</span></strong></p>
                            <p className="snuggle">Vaše maximální PNO je {pno},00&nbsp;% , tzn. při reklamní investici 10&nbsp;000,00&nbsp;Kč požadujete vygenerovaný obrat {(() => {
                                let result = (10000 / pno) * 100;
                                let formattedResult = result.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                                return formattedResult;
                            })()}&nbsp;Kč</p>
                            <p className="range js-range">
                                <input type="range" id="range" name="range" min="5" max="50" value={rangeValue} step="1" onChange={handleRangeChange} />
                                <span className="range__values">
                                    <span>5,00&nbsp;%</span>
                                    <span>50,00&nbsp;%</span>
                                </span>
                            </p>
                            <p>Změnu maximálního PNO doporučujeme konzultovat s&nbsp;naším specialistou. Příliš nízké PNO může způsobit výrazný pokles vašich tržeb.</p>
                            <p><button className="btn" onClick={(event) => updatePNO(event)}>Uložit</button></p>
                        </form>
                    </div>


                    <div className="box">
                        <h2>Nastavení limitu</h2>
                        <p>Limit slouží pro zajištění denních nákladů.</p>
                        <form action="#" method="post" className="form">
                            <p className="form__currency">
                                <label htmlFor="limit">Limit</label>
                                <input type="number" value={limitValue} max="20000" name="limit" id="limit" onChange={handleLimitChange} />
                                Kč
                            </p>
                            <p><button className="btn" onClick={(event) => updateLimit(event)}>Uložit</button></p>
                        </form>
                    </div>
                </div>

                {/* <div className="app-content__row">
                    <div className="box">
                        <h2>Přidat Správce</h2>
                        <form action="#" method="post" className="form">
                            <p>
                                <label htmlFor="manager">E-mail správce</label>
                                <input type="email" value={managerEmail} name="manager" id="manager" onChange={handleManagerEmailChange} />
                            </p>
                            <p><button className="btn">Uložit</button></p>
                        </form>
                    </div>

                    <div className="box">
                        <h2>Uživatelé s přístupem</h2>
                        <ul className="user-list">
                            {userList.map(user => (
                                <li key={user}>
                                    {user} <a href="#" onClick={() => handleUserRemove(user)}>&times;</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div> */}
            </article>
        </>
    );
};

export default AppSettings;
