import React, { useState, useContext, useEffect } from 'react';

import { AppContext } from '../context/AppContext';
import axiosInstance from './AxiosConfig';
import useUserDetails from '../hooks/useUserDetails';

import '../css/app.css';
import "../css/styles.css"

import loginGoogle from "../img/login-google.svg"
import loginFacebook from "../img/login-facebook.svg"

import CampaignsSettingModal from './CampaignsSettingModal';
import LoadingModal from './LoadingModal';
import SimpleAlert from './SimpleAlert';

const Campaigns = () => {
    const [isCampaignsSettingModal, setIsCampaignsSettingModal] = useState(false)
    const [shop, setShop] = useState([]);
    const [country, setCountry] = useState('cs');
    const [alertMessage, setAlertMessage] = useState('');
    const [saveSuccessful, setSaveSuccessful] = useState(false)
    const {
        userData,
        setUserData,
        pk,
        setPk,
        createCampaignObject,
        setcreateCampaignObject,
        campaignAssetsObject,
        setCampaignAssetsObject,
        selectedShop,
        isPmaxActive,
        setIsPmaxActive,
        isCampaignCreated,
        setIsCampaignCreated,
        hasItems,
        isCampaignDeactivated,
        setIsCampaignDeactivated,
        isUserDataLoading,
        setIsUserDataLoading,
        selectedImages,
        setSelectedImages,
        selectedLogos,
        setLogos,
        headline,
        setHeadline,
        longHeadline,
        setLongHeadline,
        shortDescPart1,
        setShortDescPart1,
        shortDescPart2,
        setShortDescPart2,
        descs,
        setDescs,
        deleteAll
    } = useContext(AppContext);

    useUserDetails(setShop, setCountry, "campaigns");

    useEffect(() => {
        if (userData.shops) {
            setcreateCampaignObject((prepState) => ({
                ...prepState,
                shop_id: selectedShop ? selectedShop.uuid : userData.shops[0].uuid,
                budget: 100, // na úrovni nastavení přepsat
                roas: 1.5,
                campaign_type: "google-pmax"
            }));
        }
    }, [userData, selectedShop]);

    useEffect(() => {
        setIsCampaignCreated(false);
    }, [selectedShop]);

    const handleModal = () => {
        setIsCampaignsSettingModal(!isCampaignsSettingModal);
    };

    const onCreateCampaign = async () => {
        try {
            setIsUserDataLoading(true)
            const response = await axiosInstance.post("campaign/create/", createCampaignObject);
            setIsCampaignCreated(true);
            setIsCampaignDeactivated(true)
            handleModal();
            setIsUserDataLoading(false)
            deleteAll()
            !hasItems && setHeadline([""])


            try {
                const responseUserData = await axiosInstance.get('user/detail/');

                if (selectedShop) {
                    const shopForObject = responseUserData.data.shops.find(oneShop => oneShop.uuid === selectedShop.uuid);
                    setCampaignAssetsObject({
                        shop_id: shopForObject.uuid,
                        asset_id: shopForObject.campaign[0].addsets[0].asset_id,
                        campaign_id: shopForObject.campaign[0].campaign_id,
                        items: [
                            {
                                name: shopForObject.name,
                                type: "BUSINESS_NAME"
                            }
                        ]
                    });
                } else {
                    const shopForObject = responseUserData.data.shops[0];
                    setCampaignAssetsObject({
                        shop_id: shopForObject.uuid,
                        asset_id: shopForObject.campaign[0].addsets[0].asset_id,
                        campaign_id: shopForObject.campaign[0].campaign_id,
                        items: [
                            {
                                name: shopForObject.name,
                                type: "BUSINESS_NAME"
                            }
                        ]
                    });
                }
            } catch (error) {
                console.log(error.response);
            }

        } catch (error) {
            console.log(error.response);
        }
    };

    const onPauseCampaign = async () => {
        try {
            let objectForPause = {
                campaign_status: isPmaxActive ? "paused" : "active"
            }

            const response = await axiosInstance.patch(`campaign/${campaignAssetsObject.campaign_id}/update/`, objectForPause)
            console.log(response.data)
        } catch (error) {
            setIsPmaxActive(!isPmaxActive)
            console.log(error.response.data)
        }
    }

    const closeAlert = () => {
        setAlertMessage(''); // Vyčistí zprávu pro zavření alertu
    };

    const showAlert = () => {
        setAlertMessage("Pro aktivování PMax kampaně je potřeba nahrát požadované položky pro tuto kampaň v nastavení!");
    };

    const onRetrieveAssets = async () => {
        deleteAll()
        setIsUserDataLoading(true)

        const response = await axiosInstance.get(`campaign/${campaignAssetsObject.campaign_id}/assets/${campaignAssetsObject.asset_id}/detail/`)
        setIsUserDataLoading(false)
        const { image_assets, text_assets } = response.data

        let countForDesc = 0
        text_assets.forEach((oneText) => {
            oneText.type === "HEADLINE" && oneText.name && setHeadline(prevState => [...prevState, oneText.name])


            if (oneText.type === "LONG_HEADLINE") {
                setLongHeadline(prevState => {
                    const newState = [...prevState];
                    const index = newState.indexOf("");
                    if (index !== -1) {
                        newState[index] = oneText.name;
                    } else {
                        newState.push(oneText.name);
                    }
                    return newState;
                });
            }

            if (oneText.type === "DESCRIPTION" && countForDesc === 0) {
                setShortDescPart1(oneText.name)
                countForDesc++
            } else if (oneText.type === "DESCRIPTION" && countForDesc === 1) {
                setShortDescPart2(oneText.name)
            }
        })

        image_assets.forEach((oneImage) => {

            oneImage.type === "LOGO" &&
                setLogos(prevState => ([
                    ...prevState, {
                        base64: `data:image/webp;base64,${oneImage.data}`,
                        name: oneImage.image_url.split("/").pop(),
                        id: oneImage.asset_item_id
                    }
                ]))

            oneImage.type === "MARKETING_IMAGE" &&
                setSelectedImages(prevState => ([
                    ...prevState, {
                        base64: `data:image/webp;base64,${oneImage.data}`,
                        name: oneImage.image_url.split("/").pop(),
                        id: oneImage.asset_item_id,
                        shape: "rect"
                    }
                ]))

            oneImage.type === "SQUARE_MARKETING_IMAGE" &&
                setSelectedImages(prevState => ([
                    ...prevState, {
                        base64: `data:image/webp;base64,${oneImage.data}`,
                        name: oneImage.image_url.split("/").pop(),
                        id: oneImage.asset_item_id,
                        shape: "square"
                    }
                ]))
        })

    }

    return (
        <>
            {isUserDataLoading && <LoadingModal />}
            <article className="app-content">
                <h1>Kampaně</h1>

                <div className="item">
                    <span className="item__ico">
                        <img src={loginGoogle} alt="" />
                    </span>
                    <span className="item__title">Performance max (PRX)</span>
                    {isCampaignCreated ?
                        <span className="item__control">
                            <a href="#" onClick={() => {
                                handleModal()
                                hasItems ? onRetrieveAssets() : setHeadline([""])
                            }} className="js-modal-settings">
                                <span className="icon icon--settings">
                                    <svg className="icon__svg">
                                        <use xlinkHref="/img/icons.svg#settings" x="0" y="0" width="100%" height="100%"></use>
                                    </svg>
                                </span>
                                Nastavení
                            </a>
                            {
                                isCampaignDeactivated ?
                                    (
                                        <label className="switcher switcher--service">
                                            <input
                                                type="checkbox"
                                                name="checkbox-inactive"
                                                disabled
                                                onClick={showAlert}
                                            />
                                            <span className="switcher__label is-unchecked" onClick={showAlert}
                                            >Neaktivní</span>
                                            <span className="switcher__back"></span>
                                        </label>
                                    )
                                    :
                                    isPmaxActive ? (
                                        hasItems ? (
                                            <label className="switcher switcher--service">
                                                <input
                                                    defaultChecked
                                                    type="checkbox"
                                                    name="checkbox-active"
                                                    onClick={() => setIsPmaxActive(!isPmaxActive)}
                                                    onChange={() => onPauseCampaign()}
                                                />
                                                <span className="switcher__label is-checked">Aktivní</span>
                                                <span className="switcher__back"></span>
                                            </label>
                                        ) : (
                                            <label className="switcher switcher--service">
                                                <input
                                                    type="checkbox"
                                                    name="checkbox-inactive"
                                                    disabled
                                                    onClick={showAlert}
                                                />
                                                <span className="switcher__label is-unchecked" onClick={showAlert}
                                                >Neaktivní</span>
                                                <span className="switcher__back"></span>
                                            </label>
                                        )
                                    ) : (
                                        <label className="switcher switcher--disabled">
                                            <input
                                                type="checkbox"
                                                name="checkbox-inactive"
                                                disabled={!hasItems}
                                                onClick={() => hasItems && setIsPmaxActive(!isPmaxActive)}
                                                onChange={() => onPauseCampaign()}
                                            />
                                            <span className="switcher__label is-unchecked">Pozastaveno</span>
                                            <span className="switcher__back"></span>
                                        </label>
                                    )}
                        </span>
                        :
                        <input type="submit" value="Vytvořit kampaň" onClick={onCreateCampaign} className="btn"></input>
                    }
                </div>
            </article>
            <CampaignsSettingModal isCampaignsSettingModal={isCampaignsSettingModal} handleModal={handleModal} setSaveSuccessful={setSaveSuccessful} />
            {alertMessage && (
                <SimpleAlert
                    message={alertMessage}
                    onClose={closeAlert} // Předá funkci pro zavření alertu
                />
            )}
        </>
    );
};

export default Campaigns;
