import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import useUserDetails from '../hooks/useUserDetails';

import { updateForm, updateUploadProducts, getFeed } from '../store/reducers/uploadProductsSlice';
import { addDangerAlert } from '../store/reducers/dangerAlertSlice';

import shoptetLogo from '../img/logos/logo-shoptet.png';
import upgatesLogo from '../img/logos/logo-upgates.png';

const UploadProductsPage = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openIndices, setOpenIndices] = useState([]);
  const [heights, setHeights] = useState({});
  const [shop, setShop] = useState([]);
  const [country, setCountry] = useState('cs');

  const formData = useSelector((state) => state.uploadProducts);
  const pk = useSelector((state) => state.pk);

  const answerRefs = useRef([]);

  const urlID = window.location.pathname.split("/").slice(-2, -1)[0];

  useUserDetails(setShop, setCountry, "upload-products")

  useEffect(() => {
    const fetchFeed = async () => {
      try {
        const data = await dispatch(getFeed(urlID)).unwrap()
        console.log("upload", data)
        dispatch(updateForm({ field: "feed", value: data.feed }))
      } catch (error) {
        console.log(error)
        navigate(`/${lng}/app/register/`);
      }

    }
    if (urlID !== "upload-products" && urlID !== "connect-google") {
      fetchFeed()
    }
  }, [urlID])

  let shoptetSteps = t('uploadProductsForm.shoptet.instructions', { returnObjects: true });
  let upgatesSteps = t('uploadProductsForm.upgates.instructions', { returnObjects: true });

  shoptetSteps = Array.isArray(shoptetSteps) ? shoptetSteps : [];
  upgatesSteps = Array.isArray(upgatesSteps) ? upgatesSteps : [];

  const validateUrl = (url) => {
    const urlPattern = /^https?:\/\/.*\..*/i;
    return urlPattern.test(url);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    dispatch(updateForm({ field: id, value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.feed && validateUrl(formData.feed)) {
      const submittedData = {
        feed: formData.feed,
        status_create: "end"
      };
      dispatch(updateUploadProducts({ id: urlID, data: submittedData }))
        .then((result) => {
          if (result.payload !== "Neplatný feed!") {
            window.scrollTo(0, 0);
            navigate(`/${lng}/app/connect-google/${urlID}`);
          }
        })
        .catch((error) => {
          dispatch(addDangerAlert({ id: Date.now(), title: 'Error', message: t('uploadProductsForm.updateFailed'), isOpen: true }));
        });
    } else {
      dispatch(addDangerAlert({ id: Date.now(), title: 'Error', message: t('uploadProductsForm.fillAllFields'), isOpen: true }));
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate(`/${lng}/app/company-details/${urlID}`);
  };

  const handleToggle = (index) => {
    setOpenIndices((prevIndices) =>
      prevIndices.includes(index)
        ? prevIndices.filter((i) => i !== index)
        : [...prevIndices, index]
    );
  };

  useEffect(() => {
    const newHeights = {};
    answerRefs.current.forEach((ref, index) => {
      if (ref) {
        newHeights[index] = ref.scrollHeight;
      }
    });
    setHeights(newHeights);
  }, []);

  useEffect(() => {
    setHeights((prevHeights) => {
      const newHeights = { ...prevHeights };
      openIndices.forEach((index) => {
        if (answerRefs.current[index]) {
          newHeights[index] = answerRefs.current[index].scrollHeight;
        }
      });
      return newHeights;
    });
  }, [openIndices]);

  const handleNavigation = (step) => {
    const steps = [
      `/app/register/${urlID}`,
      `/app/company-details/${urlID}`,
      `/app/upload-products/${urlID}`,
      `/app/connect-google/${urlID}`,
      `/app/install-tracking-code/${urlID}`
    ];
    navigate(`/${lng}${steps[step]}`);
  };

  return (
    <>
      <div className="progress">
        <div className="container">
          <ul>
            <li onClick={() => handleNavigation(0)} className="is-checked"><strong>1.</strong> {t('uploadProductsProgress.addShop')}</li>
            <li onClick={() => handleNavigation(1)} className="is-checked"><strong>2.</strong> {t('uploadProductsProgress.companyDetails')}</li>
            <li onClick={() => handleNavigation(2)} className="is-active"><strong>3.</strong> {t('uploadProductsProgress.uploadProducts')}</li>
            <li onClick={() => handleNavigation(3)}><strong>4.</strong> {t('uploadProductsProgress.connectGoogle')}</li>
            <li onClick={() => handleNavigation(4)}><strong>5.</strong> {t('uploadProductsProgress.installCode')}</li>
          </ul>
        </div>
      </div>

      {/* <DangerAlerts /> */}

      <section className="section register">
        <article className="container">
          <h1 className="title">{t('uploadProductsTitle')}</h1>
          <form onSubmit={handleSubmit} className="form">
            <input type="hidden" id="urlID" value={urlID} />

            <div className="box">
              <p>
                <label htmlFor="feed">{t('uploadProductsForm.feedUrl')}</label>
                <input
                  type="text"
                  id="feed"
                  value={formData.feed}
                  onChange={handleInputChange}
                  required
                />
              </p>

              <br />

              <p>{t('uploadProductsForm.whereToFindFeed')}</p>

              <div className="faq faq--compact">
                <div className={`faq__item ${openIndices.includes(0) ? 'is-open' : ''}`}>
                  <h3 className="faq__question" onClick={() => handleToggle(0)}>{t('uploadProductsForm.generalInstructions')}</h3>
                  <div
                    className="faq__answer"
                    ref={(el) => (answerRefs.current[0] = el)}
                    style={{ maxHeight: openIndices.includes(0) ? `${heights[0]}px` : '0' }}
                  >
                    {t('uploadProductsForm.faqGeneralAnswer')}
                  </div>
                </div>

                <div className={`faq__item ${openIndices.includes(1) ? 'is-open' : ''}`}>
                  <h3 className="faq__question" onClick={() => handleToggle(1)}>
                    <img src={shoptetLogo} alt={t('uploadProductsForm.shoptet.name')} />
                  </h3>
                  <div
                    className="faq__answer"
                    ref={(el) => (answerRefs.current[1] = el)}
                    style={{ maxHeight: openIndices.includes(1) ? `${heights[1]}px` : '0' }}
                  >
                    <ol>
                      {shoptetSteps.map((step, index) => (
                        <li key={index}>{step}</li>
                      ))}
                    </ol>
                  </div>
                </div>

                <div className={`faq__item ${openIndices.includes(2) ? 'is-open' : ''}`}>
                  <h3 className="faq__question" onClick={() => handleToggle(2)}>
                    <img src={upgatesLogo} alt={t('uploadProductsForm.upgates.name')} />
                  </h3>
                  <div
                    className="faq__answer"
                    ref={(el) => (answerRefs.current[2] = el)}
                    style={{ maxHeight: openIndices.includes(2) ? `${heights[2]}px` : '0' }}
                  >
                    <ol>
                      {upgatesSteps.map((step, index) => (
                        <li key={index}>{step}</li>
                      ))}
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="register__submit">
              <p className="register__back">
                <a href="#" onClick={handleBack}>{t('uploadProductsForm.back')}</a>
              </p>
              <p>
                <button type="submit" className="btn">{t('uploadProductsForm.submit')}</button>
              </p>
            </div>
          </form>
        </article>
      </section>
    </>
  );
};

export default UploadProductsPage;
