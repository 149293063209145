import React, { useState, useContext } from 'react';

import { AppContext } from '../context/AppContext';
import useUserDetails from '../hooks/useUserDetails';

const AppHelp = () => {
    const [shop, setShop] = useState([]);
    const [country, setCountry] = useState('cs');

    useUserDetails(setShop, setCountry, "help");

    return (
        <article className="app-content">
            <h1>Nápověda</h1>
        </article>
    );
};

export default AppHelp;
