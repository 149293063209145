import React from "react";

const TermsOfServices = () => (
    <div className="terms-of-service">
      <h2>Terms of Service</h2>
      <p>We take all measures reasonably necessary to protect User Personal Information from unauthorized access, alteration, or destruction; maintain data accuracy, and help ensure the appropriate use of User Personal Information. We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. No method of transmission, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee its absolute security.</p>
      <p>In the event we go through a business transition, such as a merger, acquisition by another company, or sale of all or a portion of its assets, your personal information will likely be among the assets transferred. You will be notified via email of any such change in ownership or control of your personal information.</p>
      <h3>Our Warranties and Disclaimers</h3>
      <p>We provide our Services using a commercially reasonable level of skill and care and we hope that you will enjoy using them. But there are certain things that we don’t promise about our Services. We provide the services on an “as is” basis.</p>
      <p>We make no warranties, expressed or implied, and hereby disclaim and negate all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. In no event shall PermoniQ be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use our software and services.</p>
      <p>We may update our privacy policy to reflect changes to our information practices. If we make any material changes, we will notify you by email (sent to the email address specified in your account) or by means of a notice on our website prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.</p>
    </div>
  );

  export default TermsOfServices