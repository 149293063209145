import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openModal } from '../store/reducers/modalSlice';
import he from 'he';
import permoniqLogo from '../img/logo.svg';

const renderHTML = (html) => {
    return { __html: he.decode(html) };
};

const HeroSection = () => {
    const { t } = useTranslation();
    const { lng } = useParams();

    const dispatch = useDispatch();

    const handleOpenModal = () => {
        dispatch(openModal());
    };



    return (
        <section className="section first-section">
            <article className="container">
                <div className="hero">
                    <div className="box box--back hero__1">
                        <h1>
                            <img src={permoniqLogo} alt="PermoniQ" width="188" height="40" />
                            <span dangerouslySetInnerHTML={renderHTML(t('hero.title'))}></span>
                        </h1>
                        <p dangerouslySetInnerHTML={renderHTML(t('hero.description'))}></p>

                        <div className="hero__cta">
                            <p>
                                <a href={`#${t('paths.tryFree')}`} className="btn" dangerouslySetInnerHTML={renderHTML(t('hero.ctaTryFree'))}></a><br />
                                <span dangerouslySetInnerHTML={renderHTML(t('hero.withoutObligations'))}></span>
                            </p>
                            <p>
                                <a className="btn btn--empty js-modal" dangerouslySetInnerHTML={renderHTML(t('hero.ctaKnowMore'))} onClick={handleOpenModal}></a>
                            </p>
                        </div>
                    </div>

                    <div className="box box--back is-connected hero__2">
                        <span className="icon icon--color-clock">
                            <svg className="icon__svg">
                                <use xlinkHref="/img/icons.svg#color-clock" />
                            </svg>
                        </span>
                        <h2>
                            <small dangerouslySetInnerHTML={renderHTML(t('hero.saving'))}></small>
                            <span dangerouslySetInnerHTML={renderHTML(t('hero.moneyAndTime'))}></span>
                        </h2>
                        <p dangerouslySetInnerHTML={renderHTML(t('hero.box2Description'))}></p>
                    </div>

                    <div className="box box--back is-connected hero__3">
                        <span className="icon icon--color-online">
                            <svg className="icon__svg">
                                <use xlinkHref="/img/icons.svg#color-online" />
                            </svg>
                        </span>
                        <h2>
                            <small dangerouslySetInnerHTML={renderHTML(t('hero.effectiveAdvertising'))}></small>
                            <span dangerouslySetInnerHTML={renderHTML(t('hero.twentyFourSeven'))}></span>
                        </h2>
                        <p dangerouslySetInnerHTML={renderHTML(t('hero.box3Description'))}></p>
                    </div>
                </div>
            </article>
        </section>
    );
};

export default HeroSection;
