import React, { useState, useRef, useEffect, useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Link } from 'react-router-dom';

import csFlag from "../img/flags/cs.png";
import enFlag from "../img/flags/en.png";
import skFlag from "../img/flags/sk.png";
import plFlag from "../img/flags/pl.png";
import huFlag from "../img/flags/hu.png";
import roFlag from "../img/flags/ro.png";
import plusIcon from "../img/icons/plus.svg";
import creditIcon from "../img/icons/credit.svg";
import loginIcon from "../img/icons/login.svg";
import inverseLogo from "../img/logo-inverse.svg";

const flags = {
  cs: csFlag,
  en: enFlag,
  sk: skFlag,
  pl: plFlag,
  hu: huFlag,
  ro: roFlag,
};

const AppNavBar = () => {
  const { t, i18n } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();
  const [isLanguageSwitchVisible, setIsLanguageSwitchVisible] = useState(false);
  const languageRef = useRef(null);
  const [selectedLanguage, setSelectedLanguage] = useState(lng || 'cs');
  const [eshop, setEshop] = useState("e-shop");
  const [shops, setShops] = useState([])
  const { userData,
    setUserData,
    selectedShop,
    setSelectedShop,
    deleteAll
  } = useContext(AppContext)

  useEffect(() => {
    Object.entries(userData).length > 0 &&
      setShops((prevState) => [...prevState, userData.shops])
  }, [userData])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language.toLowerCase());
    setSelectedLanguage(language.toLowerCase());
    setIsLanguageSwitchVisible(false);
    navigate(`/${language.toLowerCase()}/app/campaigns`);
  };

  const toggleLanguageSwitch = (e) => {
    e.preventDefault();
    setIsLanguageSwitchVisible(!isLanguageSwitchVisible);
  };

  const handleClickOutside = (event) => {
    if (languageRef.current && !languageRef.current.contains(event.target)) {
      setIsLanguageSwitchVisible(false);
    }
  };

  const showSelected = (e) => {
    let index = e.target.selectedIndex
    setSelectedShop(userData.shops[index])
  }


  return (
    <header className="header app-navbar">
      <div className="container">
        <div className="header__logo">
          <Link to={`/${lng}/app`} title={t('home')}>
            <img src={inverseLogo} alt="PermoniQ" width="151" height="32" />
          </Link>
        </div>
        <nav className="app-nav-header">
          <div className="select-main">
            {selectedShop ?
              <select name="eshop" id="eshop" value={selectedShop.name} onChange={(e) => {
                setEshop(e.target.value)
                showSelected(e)
                deleteAll()
              }}>
                {shops.length > 0 && shops[0].map((oneShop) => {
                  return (
                    <option key={oneShop.uuid} value={oneShop.name} >{oneShop.name}</option>
                  )
                })}
              </select>
              :
              <select name="eshop" id="eshop" value={eshop} onChange={(e) => {
                setEshop(e.target.value)
                showSelected(e)
                deleteAll()
              }}>
                {
                  shops.length > 0 && shops[0].map((oneShop) => {
                    return (
                      <option key={oneShop.uuid} value={oneShop.name} >{oneShop.name}</option>
                    )
                  })
                }
              </select>

            }
          </div>
          <ul>
            <li>
              <Link to="#">
                <span className="icon icon--plus">
                  <img src={plusIcon} alt="add" className="icon__svg" />
                </span>
                {t('addShop')}
              </Link>
            </li>
            <li>
              <Link to="#">
                <span className="icon icon--credit">
                  <img src={creditIcon} alt="credit" className="icon__svg" />
                </span>
                {t('remainingCredit')}
                <strong value="0 CZK"> 0 CZK</strong>
                <strong></strong>
              </Link>
            </li>
            <li>
              <Link to="#">
                <span className="icon icon--login">
                  <img src={loginIcon} alt="account" className="icon__svg" />
                </span>
                {t('myAccount')}
              </Link>
            </li>
          </ul>
          <div className="language" ref={languageRef}>
            <a href="" onClick={toggleLanguageSwitch}>
              <img src={flags[selectedLanguage]} alt={t(`loginLanguagesAppNavBar.${selectedLanguage}`)} />
            </a>
            <div className={`language__switch ${isLanguageSwitchVisible ? 'is-visible' : ''}`}>
              {Object.keys(flags).map((lang) => (
                <a href="" onClick={() => handleLanguageChange(lang.toLowerCase())} key={lang}>
                  <img src={flags[lang.toLowerCase()]} alt={t(`loginLanguagesAppNavBar.${lang.toLowerCase()}`)} /> {t(`loginLanguagesAppNavBar.${lang.toLowerCase()}`)}
                </a>
              ))}
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default AppNavBar;
