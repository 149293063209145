import React from 'react';
import closeIcon from "../img/icons/close.svg";

const SimpleAlert = ({ message, onClose }) => {
    return (
        <div className="alerts">
            <div className="alert alert--danger is-open">
                <div className="alert__message">
                    <p>{message}</p>
                </div>
                <div className="alert__close" onClick={onClose}>
                    <span className="icon icon--close">
                        <img src={closeIcon} className='icon__svg' alt="close icon" />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default SimpleAlert;
