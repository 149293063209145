import { combineReducers } from '@reduxjs/toolkit';
import stepsReducer from './stepsSlice'; // Import the steps slice
import myVariableReducer from './myVariableReducer';
import modalReducer from './modalSlice';
import loginReducer from './loginSlice';
import formReducer from './formSlice';
import companyDetailsReducer from './companyDetailsSlice';
import uploadProductsReducer from './uploadProductsSlice';
import connectGoogleReducer from './connectGoogleSlice';
import successAlertReducer from './successAlertSlice'; // Import the success alert slice
import infoAlertReducer from './infoAlertSlice'; // Import the info alert slice
import dangerAlertReducer from './dangerAlertSlice'; // Import the danger alert slice
import baseAlertReducer from './baseAlertSlice'; // Import the base alert slice
import pkReducer from './pkSlice'; // Import the pk slice
import createAnalyticsReducer from "./createAnalyticsSlice"

const rootReducer = combineReducers({
  steps: stepsReducer, // Add the steps reducer
  myVariable: myVariableReducer,
  modal: modalReducer,
  login: loginReducer,
  form: formReducer,
  companyDetails: companyDetailsReducer,
  uploadProducts: uploadProductsReducer,
  connectGoogle: connectGoogleReducer,
  successAlerts: successAlertReducer, // Add the success alert reducer
  infoAlerts: infoAlertReducer, // Add the info alert reducer
  dangerAlerts: dangerAlertReducer, // Add the danger alert reducer
  baseAlerts: baseAlertReducer, // Add the base alert reducer
  pk: pkReducer, // Add the pk reducer,
  createAnalytics: createAnalyticsReducer
});

export default rootReducer;
