import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import useUserDetails from '../hooks/useUserDetails';

import { updateGoogleAccountId, updateAgreement, fetchUserDetails, fetchShopDetails, updateConnectGoogle, googleAdsAccountCreate, updateName, updateMerchantId, updateFeed, updateVerificationCode, updateVerificationCodeSent, updateWainting } from '../store/reducers/connectGoogleSlice';
import { addDangerAlert } from '../store/reducers/dangerAlertSlice';

import "./ConnectGooglePage.css"

const ConnectGooglePage = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [haveAccounts, setHaveAccounts] = useState(true);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [shop, setShop] = useState([]);
  const [country, setCountry] = useState('cs');
  const [isGoogleAccountIdValid, setIsGoogleAccountIdValid] = useState(true);

  const { googleAccountId, email, agreement, loading, name, currency, phoneVerificationSend, phoneVerificationWaiting, phoneVerificationDone, verificationCode, verificationCodeSent, website, city, postal_code, street, phone, merchantId, feed, waiting } = useSelector((state) => state.connectGoogle);
  const pk = useSelector((state) => state.pk);

  const urlID = window.location.pathname.split("/").slice(-2, -1)[0];

  useUserDetails(setShop, setCountry, "connect-google")

  useEffect(() => {
    dispatch(updateGoogleAccountId(""))
  }, [])

  useEffect(() => {
    dispatch(updateMerchantId(merchantId))
  })

  useEffect(() => {
    const fetchDetails = async () => {
      const userDetails = await dispatch(fetchUserDetails({ pk: urlID })).unwrap();
      userDetails.shops.forEach(item => {
        if (item.uuid === urlID) {
          if (item.google_ads) {
            // navigate(`/${lng}/app/install-tracking-code/${urlID}/`);
          }
        }
      });
    };

    fetchDetails();

    const fetchShop = async () => {
      try {
        const shopDetails = await dispatch(fetchShopDetails({ pk: urlID })).unwrap();

        // if (shopDetails.google_ads) {
        //   console.log("ads exists")
        //   navigate(`/${lng}/app/install-tracking-code/${urlID}/`);
        // }



        dispatch(updateName(shopDetails.name));
        dispatch(updateFeed(shopDetails.feed))
      } catch (error) {
        console.log(error)
        navigate(`/${lng}/app/register/`);

      }
    };

    if (urlID !== "connect-google") {
      fetchShop();
    }

  }, [dispatch, urlID, navigate, lng]);

  const handleGoogleAccountIdChange = (e) => {
    const value = e.target.value;
    const googleAccountIdPattern = /^\d{3}-\d{3}-\d{4}$/;
    setIsGoogleAccountIdValid(googleAccountIdPattern.test(value));
    dispatch(updateGoogleAccountId(value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (haveAccounts && !googleAccountId) {
      dispatch(addDangerAlert({ id: Date.now(), title: 'Error', message: t('connectGoogleForm.fillAllFields'), isOpen: true }));
      return;
    }
    if (haveAccounts && !isGoogleAccountIdValid) {
      dispatch(addDangerAlert({ id: Date.now(), title: 'Error', message: t('connectGoogleForm.invalidGoogleAccountId'), isOpen: true }));
      return;
    }
    if (!haveAccounts && !agreement) {
      dispatch(addDangerAlert({ id: Date.now(), title: 'Error', message: t('connectGoogleForm.fillAllFields'), isOpen: true }));
      return;
    }

    try {
      if (haveAccounts) {
        const data = await dispatch(updateConnectGoogle({ customer_id: googleAccountId, pk: urlID })).unwrap();
      } else if (agreement) {
        await dispatch(googleAdsAccountCreate({ customer_name: name, currency_code: currency, email: email, pk: urlID })).unwrap();
      }
      window.scrollTo(0, 0);
      navigate(`/${lng}/app/install-tracking-code/${urlID}/`);
    } catch (error) {
      dispatch(addDangerAlert({ id: Date.now(), title: 'Error', message: t('connectGoogleForm.updateFailed'), isOpen: true }));
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    navigate(`/${lng}/app/upload-products/${urlID}/`);
  };

  const toggleTooltip = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const handleNavigation = (step) => {
    const steps = [
      `/app/register/`,
      `/app/company-details/`,
      `/app/upload-products/`,
      `/app/connect-google/`,
      `/app/install-tracking-code/`
    ];
    navigate(`/${lng}${steps[step]}/${urlID}/`);
  };

  return (
    <>
      <div className="progress">
        <div className="container">
          <ul>
            <li onClick={() => handleNavigation(0)} className="is-checked"><strong>1.</strong> {t('connectGoogleProgress.addShop')}</li>
            <li onClick={() => handleNavigation(1)} className="is-checked"><strong>2.</strong> {t('connectGoogleProgress.companyDetails')}</li>
            <li onClick={() => handleNavigation(2)} className="is-checked"><strong>3.</strong> {t('connectGoogleProgress.uploadProducts')}</li>
            <li onClick={() => handleNavigation(3)} className="is-active"><strong>4.</strong> {t('connectGoogleProgress.connectGoogle')}</li>
            <li onClick={() => handleNavigation(4)}><strong>5.</strong> {t('connectGoogleProgress.installCode')}</li>
          </ul>
        </div>
      </div>

      {/* <DangerAlerts /> */}

      <section className="section register">
        <article className="container">
          <h1 className="title">{t('connectGoogleTitle')}</h1>
          <form onSubmit={handleSubmit} className="form">
            <div className="box how">
              <div className="center">
                <h3>{t('connectGoogleForm.haveAccounts')}</h3>
                <p>{t('connectGoogleForm.haveAccountsDetails')}</p>
                <p>
                  <label className="switcher js-how">
                    <input
                      type="checkbox"
                      name="checkbox"
                      checked={haveAccounts}
                      onChange={() => setHaveAccounts(!haveAccounts)}
                    />
                    <span className={`switcher__label ${haveAccounts ? 'is-checked' : 'is-unchecked'}`}>{haveAccounts ? t('connectGoogleForm.yesHave') : t('connectGoogleForm.noHave')}</span>
                    <span className="switcher__back"></span>
                  </label>
                </p>
              </div>

              <br />

              {haveAccounts ? (
                <div className="how__yes">
                  <p>{t('connectGoogleForm.shareAccess')}</p>
                  <br />
                  <p>
                    <label htmlFor="gid">
                      {t('connectGoogleForm.googleAccountId')}
                      <span
                        className={`tooltip ${isTooltipOpen ? 'is-open' : ''}`}
                        onClick={toggleTooltip}
                      >
                        <span className="tooltip__icon"></span>
                        <span className="tooltip__body">
                          {t('connectGoogleForm.moreInfo')}
                          <span className="tooltip__close" onClick={toggleTooltip}>&times;</span>
                        </span>
                      </span>
                    </label>
                    <input
                      type="text"
                      id="gid"
                      value={googleAccountId}
                      onChange={handleGoogleAccountIdChange}
                      className={isGoogleAccountIdValid ? '' : 'is-invalid'}
                    />
                    {!isGoogleAccountIdValid && (
                      <span className="error">{t('connectGoogleForm.invalidGoogleAccountId')}</span>
                    )}
                  </p>
                </div>
              ) : (
                <div className="how__no">
                  <p>{t('connectGoogleForm.createAccounts')}</p>
                  <br />
                  <p>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="agreement"
                        id="agreement"
                        checked={agreement}
                        onChange={(e) => dispatch(updateAgreement(e.target.checked))}
                        required
                      />
                      <span className="checkbox__label">{t('connectGoogleForm.agreeCreate')}</span>
                      <span className="checkbox__checker"></span>
                    </label>
                  </p>
                </div>
              )}
            </div>

            <div className="register__submit">
              <p className="register__back">
                <a href="#" onClick={handleBack}>{t('connectGoogleForm.back')}</a>
              </p>
              <p>
                <button className="btn" type="submit" disabled={loading}>{t('connectGoogleForm.submit')}</button>
              </p>
            </div>
          </form>
          {
            waiting &&
            <div className="waiting">
              <div className="box is-connected center">
                <h3>Čekejte prosím, než se provedou potřebné změny.<br /></h3>
              </div>
            </div>
          }




          {/* {phoneVerificationWaiting && phoneVerificationDone ? (
        <div className="phone-verification-modal">
          <div className="box is-connected center">
            <form>
              <label htmlFor="phone-verification-code">Zadejte kód, který Vám přišel v SMS.</label>
              <input type="text" value={verificationCode} onChange={() => updateVerificationCode(true) } name="phone-verification-code" id="phone-verification-code" />
              <input type="submit" value="Odeslat" onClick={ () => updateVerificationCodeSent} className="phone-verification-submit btn" />
            </form>
          </div>
        </div>
      ) : phoneVerificationWaiting && !phoneVerificationDone ? (
        <div className="phone-verification-modal">
          <div className="box is-connected center">
            <form>
              <label htmlFor="phone-verification-code">Pro dané telefonní číslo proběhlo příliš mnoho požadavků.<br />Pokuste se o ověření později v nastavení účtu.</label>
              <input type="submit" value="Potvrdit" className="phone-verification-submit btn" />
            </form>
          </div>
        </div>
      ) : phoneVerificationSend ? (
        <div className="phone-verification-modal">
          <div className="box is-connected center">
            <h2>Čekám na odeslání ověření telefonního čísla...</h2>
          </div>
        </div>
      ) : null} */}




        </article>
      </section>
    </>
  );
};

export default ConnectGooglePage;
