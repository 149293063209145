// src/store/reducers/connectGoogleSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../components/AxiosConfig';

// Async thunk for fetching user details
export const fetchUserDetails = createAsyncThunk(
  'connectGoogle/fetchUserDetails',
  async ({ pk }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`user/detail/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for fetching shop details
export const fetchShopDetails = createAsyncThunk(
  'connectGoogle/fetchShopDetails',
  async ({ pk }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`shop/detail/${pk}/`);
      console.log("Shop Details Response:", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for updating the Google Ads ID
export const updateConnectGoogle = createAsyncThunk(
  'connectGoogle/updateConnectGoogle',
  async ({ customer_id, pk }, { getState, rejectWithValue }) => {
    try {
      const { email } = getState().connectGoogle;
      const data = {
        customer_id: customer_id.replace(/-/g, ""),
      };
      const response = await axiosInstance.post('googleads/invite/mcc/', data);
      console.log("Google Ads Invite Response:", response.data);

      if (response.data.status === "invitation_sent") {
        try {
          const responseUpdate = await axiosInstance.patch(`shop/update/${pk}/`, { google_ads: customer_id.replace(/-/g, "") });
          console.log("Shop Update Response:", responseUpdate.data);
          return responseUpdate.data; // Ensure you return responseUpdate.data here
        } catch (error) {
          console.error("Update error:", error);
          return rejectWithValue(error.response.data);
        }
      }

      return response.data;
    } catch (error) {
      alert(error.response.data.error);
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for creating Google Ads Account
export const googleAdsAccountCreate = createAsyncThunk(
  "connectGoogle/googleAdsAccountCreate",
  async ({ customer_name, currency_code, customer_id, email, pk, merchantId, feed }, { getState, rejectWithValue, dispatch }) => {

    dispatch(updateWaiting(true))

    const state = getState().connectGoogle;
    try {
      const data = {
        customer_name,
        currency_code,
      };

      const inviteData = {
        customer_id,
        email,
        access_role: "STANDARD"
      };


      const response = await axiosInstance.post("googleads/account/create/", data);
      console.log("Google Ads Account Create Response:", response.data);

      inviteData.customer_id = response.data.customer_id;

      const responseInvite = await axiosInstance.post('googleads/invite/user/', inviteData);
      console.log("Google Ads Invite Response:", responseInvite.data);

      // Merchant
      const merchantData = {
        name: data.customer_name,
        websiteUrl: state.website,
        adultContent: false,
        conversionSettings: {
          freeListingsAutoTaggingEnabled: true
        },
        address: {
          country: state.country === "cs" ? "CZ" : state.country.toUpperCase(),
          region: state.country === "cs" ? "CZ" : state.country.toUpperCase(),
          locality: state.city,
          postalCode: state.postal_code,
          streetAddress: state.street
        },
        customerService: {
          email: state.email
        },
        phoneNumber: state.phone
      };

      const createAccountMerchantResponse = await axiosInstance.post(`googlemerchant/account/create/`, merchantData);
      console.log("Merchant create:", createAccountMerchantResponse);

      // Dispatch the updateMerchantId action
      dispatch(updateMerchantId(createAccountMerchantResponse.data.customer_id));

      if (createAccountMerchantResponse.data.status === "created") {

        // dispatch(updatePhoneVerificationSend(true));

        // const phoneVerificationCreatePromise = axiosInstance.post(`googlemerchant/account/request-phone-verification/`, {
        //   account_id: createAccountMerchantResponse.data.customer_id,
        //   phoneVerificationMethod: "PHONE_CALL",
        //   languageCode: state.country,
        //   phoneNumber: state.phone,
        //   phoneRegionCode: state.country === "cs" ? "CZ" : state.country
        // })
        //   .then(phoneVerificationCreateResponse => {
        //     dispatch(updatePhoneVerificationSend(false));
        //     dispatch(updatePhoneVerificationWaiting(true));
        //     dispatch(updatePhoneVerificationDone(true))
        //     console.log("Phone verification create: ", phoneVerificationCreateResponse.data)

        //     if (state.verificationCodeSent) {
        //       const phoneNumberCreatePromise = axiosInstance.post("googlemerchant/account/verify-phone-number/", {
        //         account_id: createAccountMerchantResponse.data.customer_id,
        //         phoneVerificationMethod: "PHONE_CALL",
        //         verificationId: phoneVerificationCreateResponse.data.verification_id,
        //         verificationCode: state.verificationCode
        //       })
        //         .then(phoneNumberCreateResponse => {
        //           console.log(phoneNumberCreateResponse)
        //         })
        //         .catch(error => {
        //           console.log(error.response)
        //         })
        //     }

        //   })
        //   .catch(error => {
        //     if (error.response.data.error.includes("Too many verification requests per phone number")) {
        //       dispatch(updatePhoneVerificationWaiting(true));
        //       dispatch(updatePhoneVerificationDone(false))
        //       console.log(error.response)

        //     } else {
        //       dispatch(updatePhoneVerificationWaiting(false));
        //       alert(error.response.data.error)
        //       console.log(error.response)
        //     }
        //   })

        // Spusťte sendFeed asynchronně a nečekejte na něj
        axiosInstance.post(`googlemerchant/account/feed/`, {
          account_id: createAccountMerchantResponse.data.customer_id,
          feed_url: state.feed,
          feed_name: state.name + " " + new Date().getTime()
        }).then(sendFeedResponse => {
          console.log("Feed sent: ", sendFeedResponse.data);
        }).catch(error => {
          console.log("Error sending feed: ", error.response);
        });

        const linkData = {
          account_id: createAccountMerchantResponse.data.customer_id,
          adsLinks: [
            {
              adsId: inviteData.customer_id,
              status: "active"
            }
          ]
        };


        const linkGoogleMerchantResponse = await axiosInstance.patch(`googlemerchant/account/update/`, linkData);
        console.log("Link created: ", linkGoogleMerchantResponse.data);

        if (linkGoogleMerchantResponse.data.message === "Customer updated successfully") {
          axiosInstance.post(`googleads/invite/googlemerchant/`, { ads_id: inviteData.customer_id, gmc_id: createAccountMerchantResponse.data.customer_id })
            .then(acceptInviteResponse => {
              console.log("Invite accepted: ", acceptInviteResponse.data);
              dispatch(updateWaiting(false))
            })
            .catch(error => {
              console.log(error.response);
            });
        }

        if (response.data.status === "created") {
          try {
            const responseUpdate = await axiosInstance.patch(`shop/update/${pk}/`, {
              google_ads: inviteData.customer_id.replace(/-/g, ""),
              google_merchant: createAccountMerchantResponse.data.customer_id
            });


            console.log("Shop Update Response:", responseUpdate.data);
          } catch (error) {
            console.error("Update error:", error);
            return rejectWithValue(error.response.data);
          }
        }

        return createAccountMerchantResponse.data;
      }
    } catch (error) {
      alert(error.response.data.error);
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  googleAccountId: '',
  email: '',
  agreement: false,
  loading: false,
  name: "",
  currency: "CZK",
  website: "",
  country: "cs",
  city: "",
  postal_code: "",
  street: "",
  phone: "",
  merchantId: "",
  feed: "",
  waiting: false,
  phoneVerificationSend: false,
  phoneVerificationWaiting: false,
  phoneVerificationDone: false,
  verificationCode: "",
  verificationCodeSent: false
};

// Funkce pro generování obecného reduktoru
const createUpdateReducer = (field) => (state, action) => {
  state[field] = action.payload;
};

// Funkce pro generování obecného extra reduktoru
const handleAsyncActions = (builder, actionType) => {
  builder
    .addCase(actionType.pending, (state) => {
      state.loading = true;
    })
    .addCase(actionType.fulfilled, (state, action) => {
      Object.keys(action.payload).forEach(key => {
        state[key] = action.payload[key];
      });
      state.loading = false;
    })
    .addCase(actionType.rejected, (state) => {
      state.loading = false;
    });
};

const connectGoogleSlice = createSlice({
  name: 'connectGoogle',
  initialState,
  reducers: {
    updateGoogleAccountId: createUpdateReducer('googleAccountId'),
    updateAgreement: createUpdateReducer('agreement'),
    updateName: createUpdateReducer('name'),
    updateCurrency: createUpdateReducer('currency'),
    updateWebsite: createUpdateReducer('website'),
    updateCountry: createUpdateReducer('country'),
    updateCity: createUpdateReducer('city'),
    updatePostalCode: createUpdateReducer('postal_code'),
    updateStreet: createUpdateReducer('street'),
    updatePhone: createUpdateReducer('phone'),
    updateMerchantId: createUpdateReducer('merchantId'),
    updateFeed: createUpdateReducer('feed'),
    updateWaiting: createUpdateReducer("waiting"),
    updatePhoneVerificationSend: createUpdateReducer("phoneVerificationSend"),
    updatePhoneVerificationWaiting: createUpdateReducer("phoneVerificationWaiting"),
    updatePhoneVerificationDone: createUpdateReducer("phoneVerificationDone"),
    updateVerificationCode: createUpdateReducer("verificationCode"),
    updateVerificationCodeSent: createUpdateReducer("verificationCodeSent")
  },
  extraReducers: (builder) => {
    handleAsyncActions(builder, fetchUserDetails);
    handleAsyncActions(builder, fetchShopDetails);
    handleAsyncActions(builder, updateConnectGoogle);
    handleAsyncActions(builder, googleAdsAccountCreate);
  },
});

export const {
  updateGoogleAccountId,
  updateAgreement,
  updateName,
  updateCurrency,
  updateWebsite,
  updateCountry,
  updateCity,
  updatePostalCode,
  updateStreet,
  updatePhone,
  updateMerchantId,
  updateFeed,
  updateWaiting,
  updatePhoneVerificationSend,
  updatePhoneVerificationWaiting,
  updatePhoneVerificationDone,
  updateVerificationCode,
  updateVerificationCodeSent
} = connectGoogleSlice.actions;
export default connectGoogleSlice.reducer;
