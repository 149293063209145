import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie'; // Importujte js-cookie


const LoadingGoogleLoginPage = () => {
  const { lng } = useParams(); // Přidejte useParams zde
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const state = queryParams.get("state")


    if (code) {
      fetch('https://permoniq.com/api/auth/google/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code,
          state
        }),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Invalid login credentials');
          }
          return response.json();
        })
        .then(data => {
          const { access, refresh } = data;
          console.log(data)
          // Nastavení secure cookies s životností 7 dní
          Cookies.set("aTkn_0yD9K", access, { secure: true, sameSite: 'None', expires: 1 });
          Cookies.set("rTkn_4zF7P", refresh, { secure: true, sameSite: 'None', expires: 7 });

          window.scrollTo(0, 0);
          navigate(`/${lng}/app/register`); // Přesměrování na stránku po úspěšném přihlášení
        })
        .catch(error => {
          console.error('Error:', error);
          alert('Invalid login credentials');
        });
    };
  }, [location, navigate])

  return (
    <div>
      Čekám na přihlášení...</div>
  )
}
export default LoadingGoogleLoginPage