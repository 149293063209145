import React, { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider, useParams, useNavigate, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import CompanyDetailsPage from './components/CompanyDetailsPage';
import UploadProductsPage from './components/UploadProductsPage';
import ConnectGooglePage from './components/ConnectGooglePage';
import InstallTrackingCodePage from './components/InstallTrackingCodePage';
import RegistrationAccountPage from './components/RegistrationAccountPage';
import LoadingGoogleLoginPage from './components/LoadingGoogleLoginPage';
import NotFoundPage from './components/NotFoundPage';
import GoogleTermsPage from './components/GoogleTermsPage';
import RegisterNavBar from './components/RegisterNavBar';
import TermsOfServices from './components/TermsOfServices';
import PrivacyPolicy from './components/PrivacyPolicy';
import './i18n';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { checkLoginStatus } from './store/reducers/loginSlice';
import Cookies from 'js-cookie';
import Footer from './components/Footer';
import Loading from './components/Loading';

const EnsureTrailingSlashAndLowerCase = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    Cookies.set("prevLoc", location.pathname)
  })

  useEffect(() => {
    const path = location.pathname;
    const lowerCasePath = path.toLowerCase();
    const needsTrailingSlash = !path.endsWith('/');
    const needsLowerCase = path !== lowerCasePath;

    if (needsTrailingSlash || needsLowerCase) {
      const newPath = `${lowerCasePath}${needsTrailingSlash ? '/' : ''}`;
      navigate(newPath, { replace: true });
    }
  }, [location.pathname, navigate]);
  return children;
};

const LanguageWrapper = ({ children }) => {
  const { i18n } = useTranslation();
  const language = window.location.pathname.split('/')[1];
  const navigate = useNavigate();
  const validLanguages = ['en', 'cs', "sk", "hu", "ro", "pl"]; // Přidejte platné jazyky podle potřeby

  useEffect(() => {
    if (!validLanguages.includes(language)) {
      let newPath = window.location.pathname;
      let splitPath = newPath.split("/");
      let newPathFromThirdElement = splitPath.slice(1).join("/");
      navigate(`/cs/${newPathFromThirdElement}`, { replace: true });
    } else if (language && i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  return children;
};

const router = createBrowserRouter([
  {
    path: "/:lng/",
    element: (
      <EnsureTrailingSlashAndLowerCase>
        <LanguageWrapper>
          <Layout />
        </LanguageWrapper>
      </EnsureTrailingSlashAndLowerCase>
    ),
    children: [
      { path: "login", element: <LoginPage /> },
      { path: 'auth/google/callback/', element: <LoadingGoogleLoginPage /> },
      { path: "app/register", element: <RegisterPage /> },
      { path: "app/company-details", element: <CompanyDetailsPage /> },
      { path: "app/upload-products", element: <UploadProductsPage /> },
      { path: "app/connect-google", element: <ConnectGooglePage /> },
      { path: "app/install-tracking-code", element: <InstallTrackingCodePage /> },
      { path: "app/registration-account", element: <RegistrationAccountPage /> },
      {
        path: '*', element: (
          <>
            <header className='header'>
              <RegisterNavBar />
            </header>
            <NotFoundPage />
            <Footer />
          </>
        )
      }
    ]
  },
  {
    path: "/:lng/404", element: (
      <>
        <header className='header'>
          <RegisterNavBar />
        </header>
        <NotFoundPage />
        <Footer />
      </>
    )
  },
  {
    path: "/:lng/", element: (
      <>
        <header className='header'>
          <RegisterNavBar />
        </header>
        <NotFoundPage />
        <Footer />
      </>
    )
  },
  {
    path: "404", element: (
      <>
        <header className='header'>
          <RegisterNavBar />
        </header>
        <NotFoundPage />
        <Footer />
      </>
    )
  },
  {
    path: "*", element: (
      <EnsureTrailingSlashAndLowerCase>
        <LanguageWrapper>
          <>
            <header className='header'>
              <RegisterNavBar />
            </header>
            <NotFoundPage />
            <Footer />
          </>
        </LanguageWrapper>
      </EnsureTrailingSlashAndLowerCase>
    )
  },
  {
    path: "/google-terms", element: (
      <>
        <header className='header'>
          <RegisterNavBar />
        </header>
        <GoogleTermsPage />
        <Footer />
      </>
    )
  },
  {
    path: "/privacy-policy", element: (
      <>
        <header className='header'>
          <RegisterNavBar />
        </header>
        <PrivacyPolicy />
        <Footer />
      </>
    )
  },
  {
    path: "/terms-of-service", element: (
      <>
        <header className='header'>
          <RegisterNavBar />
        </header>
        <TermsOfServices />
        <Footer />
      </>
    )
  }
]);

const App = () => {
  const dispatch = useDispatch();
  const { lng } = useParams();
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    const refreshToken = Cookies.get('rTkn_4zF7P');
    if (!refreshToken && window.location.pathname.includes("app") && !window.location.pathname.includes("login")) {
      window.location.pathname = `/app/login/`;
    } else {
      setIsCheckingAuth(false); // Complete the auth check
    }
  }, [lng]);

  useEffect(() => {
    dispatch(checkLoginStatus());
  }, [dispatch]);

  if (isCheckingAuth) {
    return <Loading />; // Display a loading state while checking authentication
  }

  return <RouterProvider router={router} />;
};

export default App;
