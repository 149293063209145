import React from 'react';
import NavBar from './NavBar';
import LoginNavBar from './LoginNavBar';

const Header = ({ isLoginPage }) => {
  return (
    <header className='header'>
      {isLoginPage ? <LoginNavBar /> : <NavBar />}
    </header>
  );
};

export default Header;
