import React from 'react';
import { useTranslation } from 'react-i18next';
import he from 'he';
import advisioLogo from "../img/logo-advisio.svg"

const renderHTML = (html) => {
    return { __html: he.decode(html) };
};

const AdvisioSection = () => {
    const { t } = useTranslation();
    return (
        <section className="section">
            <article className="container">
                <h2 className="title" dangerouslySetInnerHTML={renderHTML(t('advisioSection.title'))}></h2>
                <div className="about">
                    <div className="box">
                        <h3 dangerouslySetInnerHTML={renderHTML(t('advisioSection.whoTitle'))}></h3>
                        <p dangerouslySetInnerHTML={renderHTML(t('advisioSection.whoText'))}></p>
                        <p>
                            <a href="https://www.advisio.cz/" className="btn" target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={renderHTML(t('advisioSection.moreAbout'))}></a>
                        </p>
                    </div>
                    <div className="box is-connected">
                        <p><img src={advisioLogo} alt="logo Advisio" width="204" height="41" loading="lazy" /></p>
                        <ul>
                            <li><strong>11</strong> {t('advisioSection.yearsOnMarket')}</li>
                            <li><strong>300+</strong> {t('advisioSection.satisfiedClients')}</li>
                            <li><strong>75</strong> {t('advisioSection.digitalAdvisors')}</li>
                        </ul>
                    </div>
                </div>
            </article>
        </section>
    );
};

export default AdvisioSection;
