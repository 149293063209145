import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import permoniqLogo from "../img/logo-inverse.svg";
import csFlag from "../img/flags/cs.png";
import enFlag from "../img/flags/en.png";
import skFlag from "../img/flags/sk.png";
import plFlag from "../img/flags/pl.png";
import huFlag from "../img/flags/hu.png";
import roFlag from "../img/flags/ro.png";

const flags = {
  cs: csFlag,
  en: enFlag,
  sk: skFlag,
  pl: plFlag,
  hu: huFlag,
  ro: roFlag,
};

const LoginNavBar = () => {
  const { t, i18n } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLanguageSwitchVisible, setIsLanguageSwitchVisible] = useState(false);
  const languageRef = useRef(null);

  const handleLanguageChange = (language) => {
    const currentPath = location.pathname.replace(`/${lng}`, `/${language}`);
    i18n.changeLanguage(language.toLowerCase());
    navigate(currentPath);
    setIsLanguageSwitchVisible(false); // Hide the language switcher after selecting a language
  };

  const toggleLanguageSwitch = (e) => {
    e.preventDefault();
    setIsLanguageSwitchVisible(!isLanguageSwitchVisible);
  };

  const handleClickOutside = (event) => {
    if (languageRef.current && !languageRef.current.contains(event.target)) {
      setIsLanguageSwitchVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="container">
      <div className="header__logo">
        <a href={`/${lng.toLowerCase()}/`} title={t('loginNav.home')}>
          <img src={permoniqLogo} alt="PermoniQ" width="151" height="32" />
        </a>
      </div>
      <nav className="app-nav-header">
        <div className="language" ref={languageRef}>
          <a href="" onClick={toggleLanguageSwitch}>
            <img src={flags[lng.toLowerCase()]} alt={t(`loginLanguages.${lng.toLowerCase()}`)} />
          </a>
          <div className={`language__switch ${isLanguageSwitchVisible ? 'is-visible' : ''}`}>
            {Object.keys(flags).map((lang) => (
              <a href="" onClick={() => handleLanguageChange(lang.toLowerCase())} key={lang}>
                <img src={flags[lang.toLowerCase()]} alt={t(`loginLanguages.${lang.toLowerCase()}`)} /> {t(`loginLanguages.${lang.toLowerCase()}`)}
              </a>
            ))}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default LoginNavBar;
