import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../components/AxiosConfig";
import { get } from "react-scroll/modules/mixins/scroller";

export const shopDetailRetrieve = createAsyncThunk(
    "shopDetailRetrieve/updateShopDetailRetrieve",
    async (pk, { getState }) => {
        const state = getState().createAnalytics

        try {
            const shopDetailsRetrieveResponse = await axiosInstance.get(`shop/detail/${pk}/`)
            return shopDetailsRetrieveResponse.data
        } catch (error) {
            throw error
        }
    }
)

export const analyticsAccountCreate = createAsyncThunk(
    "analyticsAccountCreate",
    async (_, { getState, rejectWithValue }) => {
        const state = getState().createAnalytics;
        try {
            // První operace: vytvoření analytického účtu
            const analyticsAccountCreateResponse = await axiosInstance.post(`googleanalytics/account/create/`, {
                customer_name: state.customerName,
                currency_code: state.currencyCode,
                customer_url: state.customerUrl
            });

            const propertyId = analyticsAccountCreateResponse.data.customer_id;

            // Druhá operace: aktualizace obchodu s nově vytvořeným analytickým účtem
            const updateShopResponse = await axiosInstance.patch(`shop/update/${state.pk}/`, {
                google_analytics: propertyId
            });

            return { ...analyticsAccountCreateResponse.data, ...updateShopResponse.data, propertyId };
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const analyticsConnectGoogleAds = createAsyncThunk(
    "analyticsConnectGoogleAds",
    async (_, { getState, rejectWithValue }) => {
        const state = getState().createAnalytics

        try {
            const analyticsConnectGoogleAdsResponse = await axiosInstance.post(`googleanalytics/connect/googleads/`,
                {
                    google_analytics_id: state.propertyId,
                    google_ads_id: state.googleAdsId
                }
            )
            console.log("GA4 and Google Ads connection created", analyticsConnectGoogleAdsResponse.data)
            return analyticsConnectGoogleAdsResponse.data
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

const initialState = {
    pk: "",
    customerName: "",
    currencyCode: "",
    customerUrl: "",
    propertyId: "",
    googleAdsId: "",
    loading: false,
    error: null,
}

// Funkce pro generování obecného reduktoru
const createUpdateReducer = (field) => (state, action) => {
    state[field] = action.payload;
};

// Funkce pro generování obecného extra reduktoru
const handleAsyncActions = (builder, actionType) => {
    builder
        .addCase(actionType.pending, (state) => {
            state.loading = true;
        })
        .addCase(actionType.fulfilled, (state, action) => {
            Object.keys(action.payload).forEach(key => {
                state[key] = action.payload[key];
            });
            state.loading = false;
        })
        .addCase(actionType.rejected, (state) => {
            state.loading = false;
        });
};

const createAnalyticsSlice = createSlice({
    name: "createAnalytics",
    initialState,
    reducers: {
        updatePk: createUpdateReducer("pk"),
        updateCustomerName: createUpdateReducer("customerName"),
        updateCurrencyCode: createUpdateReducer("currencyCode"),
        updateCustomerUrl: createUpdateReducer("customerUrl"),
        updatePropertyId: createUpdateReducer("propertyId"),
        updateGoogleAdsId: createUpdateReducer("googleAdsId")
    },
    extraReducers: (builder) => {
        handleAsyncActions(builder, shopDetailRetrieve)
        handleAsyncActions(builder, analyticsAccountCreate)
        handleAsyncActions(builder, analyticsConnectGoogleAds)
    },
})

export const {
    updatePk,
    updateCustomerName,
    updateCurrencyCode,
    updateCustomerUrl,
    updatePropertyId,
    updateGoogleAdsId
} = createAnalyticsSlice.actions

export default createAnalyticsSlice.reducer