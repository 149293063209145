import React, { useEffect, useState, useContext } from 'react';

import { AppContext } from '../context/AppContext';
import DangerAlerts from './DangerAlerts';
import useUserDetails from '../hooks/useUserDetails';

import logoYoutube from "../img/logos/logo-youtube.svg"
import logoGmail from "../img/logos/logo-gmail.svg"
import logoGoogle from "../img/logos/logo-google.svg"
import logoDiscover from "../img/logos/logo-discover.svg"
import logoDisplay from "../img/logos/logo-display.svg"
import plusDarkIcon from "../img/icons/plusDark.svg";
import minusIcon from "../img/icons/minus.svg"
import axiosInstance from './AxiosConfig';

const CampaignsSettingModal = ({ isCampaignsSettingModal, handleModal, setSaveSuccessful }) => {
    const [isActive, setIsActive] = useState(true)
    const [showAlert, setShowAlert] = useState(false)
    const [msg, setMsg] = useState([])
    const [msgIndex, setMsgIndex] = useState(null)
    const [isSquareImage, setIsSquareImage] = useState(false)
    const [isRectImage, setIsRectImage] = useState(false)
    const [isEnoughImages, setIsEnoughImages] = useState(false)
    const [isLogo, setIsLogo] = useState(false)
    const [isHeadline, setIsHeadline] = useState(false)
    const [isShortHeaders, setIsShortHeaders] = useState(false)
    const [isShortDesc, setIsShortDesc] = useState(false)
    const [isDescs, setIsDescs] = useState(false)
    const { campaignAssetsObject,
        setCampaignAssetsObject,
        setIsCampaignDeactivated,
        setIsUserDataLoading,
        selectedImages,
        setSelectedImages,
        selectedLogos,
        setLogos,
        headline,
        setHeadline,
        longHeadline, 
        setLongHeadline,
        shortDescPart1,
        setShortDescPart1,
        shortDescPart2,
        setShortDescPart2,
        descs,
        setDescs,
        deleteAll,
        hasItems,
        setHasItems
    } = useContext(AppContext)

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Escape") {
                handleModal()
            }
        };

        if (isCampaignsSettingModal) {
            document.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isCampaignsSettingModal]);

    useEffect(() => {
        if (msgIndex !== null) {
            closeAlert(msgIndex);
            setMsgIndex(null);  // Reset msgIndex to prevent repeated closures
        }
    }, [msgIndex]);

    useEffect(() => {
        // Limit selectedImages to a maximum of 15
        const limitedSelectedImages = selectedImages.slice(0, 15);

        // Remove duplicate selectedImages based on a unique property, e.g., 'base64'
        const uniqueSelectedImages = [];
        limitedSelectedImages.forEach((image) => {
            if (!uniqueSelectedImages.some((item) => item.base64.split(",")[1] === image.base64.split(",")[1])) {
                uniqueSelectedImages.push(image);
            }
        });

        // Update selectedImages if the unique list differs
        if (uniqueSelectedImages.length !== selectedImages.length) {
            setSelectedImages(uniqueSelectedImages);
        }

        // Calculate counts for shapes
        const squareCount = uniqueSelectedImages.filter(
            (image) => image.shape === 'square'
        ).length;
        const rectCount = uniqueSelectedImages.filter(
            (image) => image.shape === 'rect'
        ).length;

        // Update shape states
        if (squareCount > 0 && !isSquareImage) {
            setIsSquareImage(true);
        } else if (squareCount === 0 && isSquareImage) {
            setIsSquareImage(false);
        }

        if (rectCount > 0 && !isRectImage) {
            setIsRectImage(true);
        } else if (rectCount === 0 && isRectImage) {
            setIsRectImage(false);
        }

        // selectedImages count control

        isSquareImage && isRectImage && selectedImages.length > 2 ? setIsEnoughImages(true) : setIsEnoughImages(false)

        // Limit selectedLogos to a maximum of 5
        const limitedLogos = selectedLogos.slice(0, 5);

        // Remove duplicate selectedLogos based on a unique property, e.g., 'base64'
        const uniqueLogos = [];
        limitedLogos.forEach((logo) => {
            if (!uniqueLogos.some((item) => item.base64 === logo.base64)) {
                uniqueLogos.push(logo);
            }
        });

        // Update selectedLogos if the unique list differs
        if (uniqueLogos.length !== selectedLogos.length) {
            setLogos(uniqueLogos);
        }

        // Update logo presence state
        if (selectedLogos.length > 0 && !isLogo) {
            setIsLogo(true);
        } else if (selectedLogos.length === 0 && isLogo) {
            setIsLogo(false);
        }
    }, [selectedImages, selectedLogos, isSquareImage, isRectImage, isLogo]);

    useEffect(() => {
        // longHeader control
        const longHeaderValidCount = headline.filter(headline => headline.length >= 3).length;
        longHeaderValidCount > 0 ? setIsHeadline(true) : setIsHeadline(false);

        // longHeadline control
        let shortHeadersCount = 0;
        longHeadline.forEach((oneShortHeader) => {
            oneShortHeader.length > 2 && shortHeadersCount++;
        });

        shortHeadersCount > 0 ? setIsShortHeaders(true) : setIsShortHeaders(false);

        // shortDesc control
        const totalShortDescLength = shortDescPart1.length + shortDescPart2.length;
        if (totalShortDescLength > 2 && totalShortDescLength <= 60) {
            setIsShortDesc(true);
        } else {
            setIsShortDesc(false);
        }

        // descs control
        let descsCount = 0;
        descs.forEach((oneDesc) => {
            oneDesc.length > 2 && descsCount++;
        });

        descsCount > 0 ? setIsDescs(true) : setIsDescs(false);

    }, [headline, longHeadline, shortDescPart1, shortDescPart2, descs]);




    const handleActive = () => {
        setIsActive(!isActive)  // Dočasně nepoužíváno z důvodu nezobrazení preview.
    }

    const closeModal = (e) => {
        if (e.target === e.currentTarget) {
            handleModal();
        }
    }


    const closeAlert = (index) => {
        setMsg((prevMsgs) => prevMsgs.filter((_, i) => i !== index));
    }

    const handleItem = (e) => {
        let parent = e.target.parentElement

        if (!e.target.className.includes("settings__item")) {
            if (parent.classList.value.includes("is-open")) {
                parent.classList.remove("is-open")
            } else {
                parent.classList.add("is-open")
            }
        }
    }

    const handleImageChange = (e) => {
        const files = e.target.files; // Get all selected files
        const fileArray = [...files]; // Convert FileList to an array

        if (files && selectedImages.length + fileArray.length <= 15) {
            fileArray.forEach((file, index) => {
                let newImage = {
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    id: new Date().getTime() + index // Ensure unique IDs for each image
                };

                const reader = new FileReader();
                reader.onload = () => {
                    newImage.base64 = reader.result;
                    let img = new Image();
                    img.onload = () => {
                        newImage.width = img.width;
                        newImage.height = img.height;

                        if (newImage.width === newImage.height) {
                            if (newImage.size / 1024 <= 5120 && newImage.width >= 300 && newImage.height >= 300) {
                                newImage.shape = "square";
                                setSelectedImages((prevImg) => [...prevImg, newImage]);
                                setIsSquareImage(true);
                            } else if (newImage.size / 1024 > 5120) {
                                setMsg((prevMsg) => [...prevMsg, `Maximální velikost obrázku ${newImage.name} je 5120 kB!`]);
                                setShowAlert(true);
                            } else if (newImage.width < 300 || newImage.height < 300) {
                                setMsg((prevMsg) => [...prevMsg, `Minimální výška a šířka čtvercového obrázku ${newImage.name} je 300px!`]);
                                setShowAlert(true);
                            } else {
                                setMsg((prevMsg) => [...prevMsg, `Vyskytla se chyba u obrázku ${newImage.name}!`]);
                                setShowAlert(true);
                            }
                        } else if ((newImage.width / newImage.height).toFixed(2) === "1.91") {
                            if (newImage.size / 1024 <= 5120 && newImage.width >= 600 && newImage.height >= 314) {
                                newImage.shape = "rect";
                                setSelectedImages((prevImg) => [...prevImg, newImage]);
                                setIsRectImage(true);
                            } else if (newImage.size / 1024 > 5120) {
                                setMsg((prevMsg) => [...prevMsg, `Maximální velikost obrázku ${newImage.name} je 5120 kB!`]);
                                setShowAlert(true);
                            } else if (newImage.width < 600 || newImage.height < 314) {
                                setMsg((prevMsg) => [...prevMsg, `Obrázek ${newImage.name} na šířku má minimální šířku 600px a minimální výšku 314px!`]);
                                setShowAlert(true);
                            } else {
                                setMsg((prevMsg) => [...prevMsg, `Vyskytla se chyba u obrázku ${newImage.name}!`]);
                                setShowAlert(true);
                            }
                        } else {
                            setMsg((prevMsg) => [...prevMsg, `Obrázek ${newImage.name} nemohl být nahrán z důvodu špatných rozměrů!`]);
                            setShowAlert(true);
                        }
                    };

                    img.src = newImage.base64;
                };

                reader.readAsDataURL(file);
            });
        } else if (selectedImages.length >= 15) {
            setMsg((prevMsg) => [...prevMsg, "Maximální počet obrázků je 15!"]);
            setShowAlert(true);
        }

        e.target.value = "";
    };

    const handleDeleteImage = (id) => {
        setSelectedImages(selectedImages.filter((oneImage) => oneImage.id !== id))
    }

    const handleLogoChange = (e) => {

        const file = e.target.files;
        const fileArray = [...file]

        if (file && selectedLogos.length < 5) {

            fileArray.forEach((oneFile, index) => {
                let newLogo = {
                    name: oneFile.name,
                    size: oneFile.size,
                    type: oneFile.type,
                    id: new Date().getTime() + index
                }
                const reader = new FileReader();
                reader.onload = () => {
                    newLogo.base64 = reader.result
                    let img = new Image()

                    img.onload = () => {
                        newLogo.width = img.width
                        newLogo.height = img.height

                        if (img.width === img.height) {
                            if (img.width >= 128 && img.height >= 128) {
                                if (newLogo.size / 1024 <= 5120) {
                                    setLogos((prevLogo) => [...prevLogo, newLogo])
                                    setIsLogo(true)
                                } else {
                                    setMsg((prevMsg) => [...prevMsg, `Velikost loga ${newLogo.name} nesmí být větší než 5120kB !`])
                                }
                            } else {
                                setMsg((prevMsg) => [...prevMsg, `Výška a šířka loga ${newLogo.name} musí být minimáně 128px!`])
                            }
                        } else {
                            setMsg((prevMsg) => [...prevMsg, `Výška a šířka loga ${newLogo.name} se musí rovnat!`])
                        }
                    };

                    img.src = newLogo.base64

                }
                reader.readAsDataURL(oneFile);
            })

        } else if (selectedLogos.length >= 5) {
            setMsg((prevMsg) => [...prevMsg, "Maximální počet log je 5!"])
            setShowAlert(true)
        }

        e.target.value = ""

    }

    const handleDeleteLogo = (id) => {
        setLogos(selectedLogos.filter((oneLogo) => oneLogo.id !== id))
    }

    const handleInputChange = (index, value) => {
        const newShortHeaders = [...longHeadline];
        newShortHeaders[index] = value;
        setLongHeadline(newShortHeaders);
    };

    const handleLongHeaderChange = (index, value) => {
        const newLongHeaders = [...headline];
        newLongHeaders[index] = value;
        setHeadline(newLongHeaders);
    };

    const addLongHeader = () => {
        if (headline.length < 15) {
            setHeadline([...headline, ""]);
        }
    };

    const removeLongHeader = (index) => {
        if (headline.length > 1) {
            const newLongHeaders = headline.filter((_, i) => i !== index);
            setHeadline(newLongHeaders);
        }
    };

    const sendAssetObject = async (campaign_id, asset_id, objectForSend) => {
        try {
            console.log(objectForSend)
            setIsUserDataLoading(true)
            const response = await axiosInstance.post(`campaign/${campaign_id}/assets/${asset_id}/update/`, objectForSend)
            console.log(response.data)
            setSaveSuccessful(true)
            handleModal()
            setIsUserDataLoading(false)
        } catch (error) {
            setIsUserDataLoading(false)
            console.log(error.response)
        }
    }

    const onSave = async (e) => {
        if (isEnoughImages && isLogo && isHeadline && isShortHeaders && isShortDesc) {
            // Naplnění objektu novými hodnotami, včetně zachování BUSINESS_NAME
            const updatedObject = {
                ...campaignAssetsObject,
                items: [
                    ...(campaignAssetsObject.items || []).filter(item => item.type === "BUSINESS_NAME"), // Zachování položky BUSINESS_NAME
                    ...headline
                        .filter(oneHeadline => oneHeadline) // Filtrovat pouze ne-prázdné headliny
                        .map(oneHeadline => ({
                            name: oneHeadline,
                            type: "HEADLINE"
                        })),
                    ...(shortDescPart1 ? [{
                        name: shortDescPart1,
                        type: "DESCRIPTION"
                    }] : []), // Přidat první část popisu jen pokud není prázdná
                    ...(shortDescPart2 ? [{
                        name: shortDescPart2,
                        type: "DESCRIPTION"
                    }] : []), // Přidat druhou část popisu jen pokud není prázdná
                    ...longHeadline
                        .filter(oneLongHeadline => oneLongHeadline) // Filtrovat pouze ne-prázdné long headliny
                        .map(oneLongHeadline => ({
                            name: oneLongHeadline,
                            type: "LONG_HEADLINE"
                        })),
                    ...selectedLogos
                        .filter(oneLogo => oneLogo.name) // Filtrovat pouze loga s ne-prázdným name
                        .map(oneLogo => ({
                            name: oneLogo.name,
                            type: "LOGO",
                            original_image: oneLogo.base64
                        })),
                    ...selectedImages
                        .filter(oneImage => oneImage.name) // Filtrovat pouze obrázky s ne-prázdným name
                        .map(oneImage => ({
                            name: oneImage.name,
                            type: oneImage.shape === "square" ? "SQUARE_MARKETING_IMAGE" : "MARKETING_IMAGE",
                            original_image: oneImage.base64
                        }))
                ]
            };

            // Vypočítání velikosti payloadu
            const payloadSize = new Blob([JSON.stringify({ items: [...updatedObject.items] })]).size;
            console.log(`Velikost payloadu: ${payloadSize} bytů`);

            // Odeslání dat po aktualizaci objektu
            await sendAssetObject(updatedObject.campaign_id, updatedObject.asset_id, { items: [...updatedObject.items] });
            const response = await axiosInstance.patch(`campaign/${campaignAssetsObject.campaign_id}/update/`, {
                campaign_status: "paused"
            })

            // Vyprázdnění objektu po úspěšném odeslání (případně dle potřeby)
            setCampaignAssetsObject((prevState) => ({
                ...prevState,
                items: [
                    ...prevState.items.filter(item => item.type === "BUSINESS_NAME") // Zachování pouze BUSINESS_NAME po odeslání
                ]
            }));
            setHasItems(true)
            deleteAll()
            setIsCampaignDeactivated(false)

        } else {
            setMsg((prevMsg) => [...prevMsg, `Některé z polí není řádně vyplněno!`]);
        }
    };






    return (
        <div className={`modal modal--settings ${isCampaignsSettingModal && "is-open"}`} id="modal-1" onClick={closeModal}>

            <DangerAlerts messages={[...msg]} setMsgIndex={setMsgIndex} />

            <div className="modal__body">

                <div className="modal__content">

                    <div className="box box--settings">
                        <form className="form">

                            <div className="tabs">
                                <ul>
                                    <li className={`tabs__item is-active`} data-tab="settings">Nastavení produktů</li>
                                    {/* <li onClick={() => handleActive()} className={`tabs__item ${!isActive && "is-active"}`} data-tab="preview">Náhled produktů</li> */}
                                </ul>
                            </div>

                            <div className={`settings ${isActive && `is-open`}`}>

                                <div className={`settings__item ${hasItems && "is-open"}`} onClick={handleItem}>

                                    <h2 className="settings__title">
                                        Nahrajte až 15 obrázků
                                        <small>Minimálně 3 obrázky jsou povinné</small>
                                    </h2>

                                    <div className="settings__content">

                                        <div className="settings__note">
                                            <p>Obrázky musí splnit následující rozměry a nebo mohou být oříznuté do doporučených rozměrů. Maximální velikost jednoho obrázku je 5 120 KB.</p>
                                            <ul>
                                                <li>Čtvercový obrázek je povinný (1:1), doporučená velikost 1 200 x 1 200, minimální velikost 300 x 300</li>
                                                <li>Široký obrázek je povinný (1,91:1), doporučená velikost 1 200 x 628, minimální velikost 600 x 314</li>
                                            </ul>
                                        </div>

                                        <p className="form__file">
                                            <label>
                                                <input type="file" id="file-1" name="file-1" multiple accept="image/png, image/jpeg" onChange={handleImageChange} />
                                                <span><span className="btn btn--secondary">Vyberte soubor z počítače</span></span>
                                            </label>
                                        </p>

                                        {selectedImages.length > 0 &&
                                            selectedImages.map((oneImage) => {

                                                return (
                                                    <p className="file" key={oneImage.id}>
                                                        <span className="file__image"><img src={oneImage.base64} alt="uploaded image" /></span>
                                                        <span className="file__name"><em>{oneImage.name}</em></span>
                                                        <a href="#" onClick={() => handleDeleteImage(oneImage.id)} className="file__delete">
                                                            <span className="icon icon--delete"><svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink"><use xlinkHref="/img/icons.svg#delete" x="0" y="0" width="100%" height="100%"></use></svg></span>
                                                            Smazat
                                                        </a>
                                                    </p>
                                                )
                                            })

                                        }

                                    </div>

                                </div>

                                <div className={`settings__item ${hasItems && "is-open"}`} onClick={handleItem}>

                                    <h2 className="settings__title">
                                        Nahrajte až 5 log
                                        <small>Minimálně 1 logo je povinné</small>
                                    </h2>

                                    <div className="settings__content">

                                        <div className="settings__note">
                                            <p>Loga musí splnit následující rozměry a nebo mohou být oříznuté do doporučených rozměrů. Maximální velikost jednoho loga je 5 120 KB.</p>
                                            <ul>
                                                <li>Čtvercové logo je povinné (1:1), doporučená velikost 1 200 x 1 200, minimální velikost 128 x 128</li>
                                                <li>Široké logo je dobrovolné (4:1), doporučená velikost 1 200 x 300, minimální velikost 512 x 128</li>
                                            </ul>
                                        </div>

                                        <p className="form__file">
                                            <label>
                                                <input type="file" id="logo-1" multiple name="logo-1" accept="image/png, image/jpeg" onChange={handleLogoChange} />
                                                <span><span className="btn btn--secondary">Vyberte soubor z počítače</span></span>
                                            </label>
                                        </p>

                                        {selectedLogos.length > 0 &&
                                            selectedLogos.map((oneLogo) => {
                                                return (
                                                    <p className="file" key={oneLogo.id}>
                                                        <span className="file__image"><img src={oneLogo.base64} alt="uploaded image" /></span>
                                                        <span className="file__name"><em>{oneLogo.name}</em></span>
                                                        <a href="#" onClick={() => handleDeleteLogo(oneLogo.id)} className="file__delete">
                                                            <span className="icon icon--delete"><svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink"><use xlinkHref="/img/icons.svg#delete" x="0" y="0" width="100%" height="100%"></use></svg></span>
                                                            Smazat
                                                        </a>
                                                    </p>
                                                )
                                            })

                                        }

                                    </div>

                                </div>

                                <div className={`settings__item ${hasItems && "is-open"}`} onClick={handleItem}>

                                    <h2 className="settings__title">
                                        Nadpis
                                        <small>Nadpis je povinný a musí mít 3 - 30 znaků. Je možné přidat až 15 nadpisů.</small>
                                    </h2>

                                    <div className="settings__content">
                                        {headline.map((headline, index) => (
                                            <div key={index}>
                                                <span className="form__limit">
                                                    <em>{headline.length}</em>/30 znaků
                                                </span>
                                                <div className="headline-container">
                                                    <input
                                                        name={`longtitle-${index + 1}`}
                                                        id={`longtitle-${index + 1}`}
                                                        value={headline}
                                                        onChange={(e) => handleLongHeaderChange(index, e.target.value)}
                                                        className="js-form-limit no-margin"
                                                        maxLength="30"
                                                    />
                                                    <img
                                                        src={minusIcon}
                                                        className='remove-headline-minus-icon'
                                                        alt='Remove headline'
                                                        onClick={() => removeLongHeader(index)}
                                                        style={{ cursor: 'pointer' }}
                                                    />

                                                    <img
                                                        src={plusDarkIcon}
                                                        className='add-headline-plus-icon'
                                                        alt="Add headline"
                                                        onClick={addLongHeader}
                                                        style={{ cursor: 'pointer' }}
                                                    />

                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>

                                <div className={`settings__item ${hasItems && "is-open"}`} onClick={handleItem}>

                                    <h2 className="settings__title">
                                        5 dlouhých nadpisů
                                        <small>Minimálně 1 dlouhý nadpis je povinný. Dlouhé nadpisy musí mít 3 - 90 znaků</small>
                                    </h2>

                                    <div className="settings__content">
                                        {longHeadline.map((headline, index) => (
                                            <p key={index}>
                                                <span className="form__limit">
                                                    <em>{headline.length}</em>/90 znaků
                                                </span>
                                                <input
                                                    name={`title-1-${index + 1}`}
                                                    id={`title-1-${index + 1}`}
                                                    className="js-form-limit"
                                                    value={headline}
                                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                                    maxLength="90"
                                                />
                                            </p>
                                        ))}
                                    </div>

                                </div>

                                <div className={`settings__item ${hasItems && "is-open"}`} onClick={handleItem}>

                                    <h2 className="settings__title">
                                        Popis
                                        <small>Popis musí být unikátní a musí mít 3 - 60 znaků</small>
                                    </h2>

                                    <div className="settings__content">

                                        <p>
                                            <span className="form__limit"><em>{shortDescPart1.length + shortDescPart2.length}</em>/60 znaků</span>
                                            <input
                                                type="text"
                                                name="description-1-part1"
                                                id="description-1-part1"
                                                value={shortDescPart1}
                                                onChange={(e) => setShortDescPart1(e.target.value)}
                                                maxLength="30"
                                                placeholder="První část (max 30 znaků)"
                                                className="js-form-limit"
                                            />
                                            <input
                                                type="text"
                                                name="description-1-part2"
                                                id="description-1-part2"
                                                value={shortDescPart2}
                                                onChange={(e) => setShortDescPart2(e.target.value)}
                                                maxLength="30"
                                                placeholder="Druhá část (max 30 znaků)"
                                                className="js-form-limit"
                                            />
                                        </p>

                                    </div>

                                </div>


                                {/* <div className={`settings__item`} onClick={handleItem}>

                                        <h2 className="settings__title">
                                            4 Dlouhé popisy
                                            <small>Minimálně 1 dlouhý popis je povinný a musí mít 3 - 90 znaků</small>
                                        </h2>

                                        <div className="settings__content">

                                            {descs.map((desc, index) => (
                                                <p key={index}>
                                                    <span className="form__limit">
                                                        <em>{desc.length}</em>/90 znaků
                                                    </span>
                                                    <input
                                                        name={`keyword-1-${index + 1}`}
                                                        id={`keyword-1-${index + 1}`}
                                                        className="js-form-limit"
                                                        value={desc}
                                                        onChange={(e) => {
                                                            const newDescs = [...descs];
                                                            newDescs[index] = e.target.value;
                                                            setDescs(newDescs);
                                                        }}
                                                        maxLength="90"
                                                    />
                                                </p>
                                            ))}
                                        </div>

                                    </div> */}

                            </div>

                            {/* <div className={`preview ${!isActive && "is-open"}`}>

                                <ul className="preview__switch">
                                    <li data-switch="youtube" className="is-active">
                                        <img src={logoYoutube} alt="logo Youtube" width="40" height="40" loading="lazy" />
                                        <em>Youtube</em>
                                    </li>
                                    <li data-switch="gmail">
                                        <img src={logoGmail} alt="logo Gmail" width="40" height="40" loading="lazy" />
                                        <em>Gmail</em>
                                    </li>
                                    <li data-switch="google">
                                        <img src={logoGoogle} alt="logo Google Search" width="40" height="40" loading="lazy" />
                                        <em>Google Search</em>
                                    </li>
                                    <li data-switch="discover">
                                        <img src={logoDiscover} alt="logo Google Discover" width="40" height="40" loading="lazy" />
                                        <em>Google Discover</em>
                                    </li>
                                    <li data-switch="display">
                                        <img src={logoDisplay} alt="logo Google Display" width="40" height="40" loading="lazy" />
                                        <em>Google Display</em>
                                    </li>
                                </ul>

                                <div className="preview__detail youtube is-open">
                                    Youtube
                                </div>

                                <div className="preview__detail gmail">
                                    Gmail
                                </div>

                                <div className="preview__detail google">
                                    Google Search
                                </div>

                                <div className="preview__detail discover">
                                    Google Discover
                                </div>

                                <div className="preview__detail display">
                                    Google Display
                                </div>

                            </div> */}

                            <p className="modal__submit">
                                <button className="btn btn--secondary modal__cancel" onClick={(e) => {
                                    e.preventDefault()
                                    handleModal()
                                }}>Zrušit</button>
                                <button className="btn" onClick={(e) => {
                                    e.preventDefault()
                                    onSave()
                                }}>Uložit</button>
                            </p>

                        </form>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default CampaignsSettingModal;
