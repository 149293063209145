import { createSlice } from '@reduxjs/toolkit';

const initialState = '';

const myVariableSlice = createSlice({
  name: 'myVariable',
  initialState,
  reducers: {
    setMyVariable: (state, action) => action.payload
  }
});

export const { setMyVariable } = myVariableSlice.actions;
export default myVariableSlice.reducer;
