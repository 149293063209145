import { createSlice } from '@reduxjs/toolkit';

const dangerAlertSlice = createSlice({
  name: 'dangerAlerts',
  initialState: {
    alerts: [] // Initialize as an empty array
  },
  reducers: {
    addDangerAlert: (state, action) => {
      state.alerts.push(action.payload);
    },
    closeDangerAlert: (state, action) => {
      state.alerts = state.alerts.filter(alert => alert.id !== action.payload);
    },
  },
});

export const { addDangerAlert, closeDangerAlert } = dangerAlertSlice.actions;
export default dangerAlertSlice.reducer;
