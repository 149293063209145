import React from 'react';
import closeIcon from "../img/icons/close.svg";

const Alert = ({ messages, setMsgIndex  }) => {
  return (
    <div className="alerts">
      {messages && messages.map((msg, index) => (
        <div key={index} className="alert alert--danger is-open">
          <div className="alert__message">
            <p>{msg}</p>
          </div>
          <div className="alert__close" onClick={() => setMsgIndex(index)}>
            <span className="icon icon--close">
              <img src={closeIcon} className='icon__svg' alt="close icon" />
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Alert;
