// src/store/slices/loginSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const refreshAccessToken = createAsyncThunk('login/refreshAccessToken', async (refreshToken, { rejectWithValue }) => {
    try {
        const response = await fetch('https://permoniq.com/api/token/refresh/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refresh: refreshToken }),
        });

        if (!response.ok) {
            throw new Error('Failed to refresh access token');
        }
        
        const data = await response.json();
        console.log(data)
        Cookies.set('aTkn_0yD9K', data.access, { secure: true, sameSite: 'None', expires: 1 });
        return data.access;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const checkIsLogged = async (dispatch) => {
    const accessToken = Cookies.get('aTkn_0yD9K');
    const refreshToken = Cookies.get('rTkn_4zF7P');

    if (accessToken && refreshToken) {
        return true;
    }

    if (refreshToken) {
        try {
            await dispatch(refreshAccessToken(refreshToken)).unwrap();
            return true;
        } catch (error) {
            console.error('Token refresh failed:', error);
        }
    }

    return false;
};

const initialState = {
  isLogged: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    showLogin: (state) => {
      state.isLogged = true;
    },
    hideLogin: (state) => {
      state.isLogged = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(refreshAccessToken.fulfilled, (state) => {
      state.isLogged = true;
    });
    builder.addCase(refreshAccessToken.rejected, (state) => {
      state.isLogged = false;
    });
  }
});

export const { showLogin, hideLogin } = loginSlice.actions;

export const checkLoginStatus = () => async (dispatch) => {
  const isLogged = await checkIsLogged(dispatch);
  if (isLogged) {
    dispatch(showLogin());
  } else {
    dispatch(hideLogin());
  }
};

export default loginSlice.reducer;
