import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import useUserDetails from '../hooks/useUserDetails';

import { updateForm, getCompanyInfo } from '../store/reducers/companyDetailsSlice';
import { setStepCompleted } from '../store/reducers/stepsSlice';
import { addDangerAlert } from '../store/reducers/dangerAlertSlice';

import axiosInstance from './AxiosConfig';

import csFlag from "../img/flags/cs.png";
import enFlag from "../img/flags/en.png";
import skFlag from "../img/flags/sk.png";
import plFlag from "../img/flags/pl.png";
import huFlag from "../img/flags/hu.png";
import roFlag from "../img/flags/ro.png";

const flags = {
  cs: csFlag,
  en: enFlag,
  sk: skFlag,
  pl: plFlag,
  hu: huFlag,
  ro: roFlag,
};

const countryIcoFormats = {
  cs: /^\d{8}$/, // Czech Republic
  sk: /^\d{8}$/, // Slovakia
  hu: /^\d{8}$/, // Hungary
  pl: /^\d{10}$/, // Poland
  ro: /^\d{2,10}$/ // Romania
};

const CompanyDetailsPage = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [country, setCountry] = useState('cs');
  const [errors, setErrors] = useState({});
  const [isCountrySelectOpen, setIsCountrySelectOpen] = useState(false);
  const [shop, setShop] = useState([]);

  const formData = useSelector((state) => state.companyDetails);
  const steps = useSelector((state) => state.steps.steps);
  const pk = useSelector((state) => state.pk);

  const urlID = window.location.pathname.split("/").slice(-2, -1)[0];

  useUserDetails(setShop, setCountry, "company-details")

  useEffect(() => {
    if (lng === 'en') {
      setCountry('cs');
    } else {
      setCountry(lng);
    }
  }, [lng]);

  useEffect(() => {

    const fetchCompanyInfo = async () => {
      try {
        const data = await dispatch(getCompanyInfo(urlID)).unwrap();
        handleCountryChange(data.country_billing)

      } catch (error) {
        dispatch(addDangerAlert({ id: Date.now(), title: 'Error', message: t('companyDetailsForm.loadFailed'), isOpen: true }));

        navigate(`/${lng}/app/register/`);

      }
    }

    if (urlID !== "company-details" && urlID !== "upload-products") {
      fetchCompanyInfo();
    }

  }, [urlID]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    dispatch(updateForm({ field: id, value }));
    setErrors({ ...errors, [id]: value.trim() === '' });
  };

  const toggleCountry = () => {
    setIsCountrySelectOpen(!isCountrySelectOpen);
  };

  const handleCountryChange = (lang) => {
    setCountry(lang);
    setIsCountrySelectOpen(false); // Close the dropdown after selecting a country
  };

  const validateFields = () => {
    const newErrors = {};
    const namePattern = /^[a-zA-Zá-žÁ-Ž]+$/; // Kontrola na písmena
    const zipPattern = /^[0-9]+$/; // Kontrola na čísla

    if (!formData.name || formData.name.trim() === '') {
      newErrors.name = true;
    }
    if (!formData.street || formData.street.trim() === '') {
      newErrors.street = true;
    }
    if (!formData.city || !namePattern.test(formData.city)) {
      newErrors.city = true;
    }
    if (!formData.postal_code || !zipPattern.test(formData.postal_code)) {
      newErrors.postal_code = true;
    }
    if (!formData.ico || !countryIcoFormats[country].test(formData.ico)) {
      newErrors.ico = true;
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateFields();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      dispatch(addDangerAlert({ id: Date.now(), title: 'Error', message: t('companyDetailsForm.fillAllFields'), isOpen: true }));
    } else {
      const submittedData = {
        name: formData.name,
        street: formData.street,
        city: formData.city,
        postal_code: formData.postal_code,
        ico: formData.ico,
        dic: formData.dic,
        email: formData.email,
        country_billing: country // Use the selected country for billing
      };
      console.log('Submitted data:', submittedData);

      try {
        const response = await axiosInstance.put(`shop/update/${urlID}/`, submittedData);
        console.log('Update successful:', response.data);
        dispatch(setStepCompleted({ index: 1, value: true }));
        window.scrollTo(0, 0);
        navigate(`/${lng}/app/upload-products/${urlID}`);
      } catch (error) {
        console.error('Update failed:', error.response.data);
        dispatch(addDangerAlert({ id: Date.now(), title: 'Error', message: t('companyDetailsForm.updateFailed'), isOpen: true }));
      }
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    navigate(`/${lng}/app/register/${urlID}`);
  };

  const handleStepClick = (stepIndex) => {
    if (steps[stepIndex]) {
      const stepPaths = [
        `/app/register/${urlID}`,
        `/app/company-details/${urlID}`,
        `/app/upload-products/${urlID}`,
        `/app/connect-google/${urlID}`,
        `/app/install-tracking-code/${urlID}`
      ];
      navigate(`/${lng}${stepPaths[stepIndex]}`);
    }
  };

  return (
    <>
      <div className="progress">
        <div className="container">
          <ul>
            <li className="is-checked" onClick={() => handleStepClick(0)}><strong>1.</strong> {t('companyDetailsProgress.addShop')}</li>
            <li className="is-active" onClick={() => handleStepClick(1)}><strong>2.</strong> {t('companyDetailsProgress.companyDetails')}</li>
            <li className={steps[2] ? 'is-checked' : ''} onClick={() => handleStepClick(2)}><strong>3.</strong> {t('companyDetailsProgress.uploadProducts')}</li>
            <li className={steps[3] ? 'is-checked' : ''} onClick={() => handleStepClick(3)}><strong>4.</strong> {t('companyDetailsProgress.connectGoogle')}</li>
            <li className={steps[4] ? 'is-checked' : ''} onClick={() => handleStepClick(4)}><strong>5.</strong> {t('companyDetailsProgress.installCode')}</li>
          </ul>
        </div>
      </div>

      {/* <DangerAlerts /> */}

      <section className="section register">
        <article className="container">
          <h1 className="title">{t('companyDetailsTitle')}</h1>
          <form action="#" method="post" className="form" onSubmit={handleSubmit}>
            <input type="hidden" id="urlID" value={urlID} />
            <div className="box">
              <div className="form__column">
                <p>
                  <label htmlFor="name">{t('companyDetailsForm.company')}</label>
                  <input type="text" id="name" value={formData.name || ''} onChange={handleInputChange} />
                  {errors.name && <span className="error">{t('companyDetailsForm.required')}</span>}
                </p>
                <p>
                  <label htmlFor="street">{t('companyDetailsForm.street')}</label>
                  <input type="text" id="street" value={formData.street || ''} onChange={handleInputChange} />
                  {errors.street && <span className="error">{t('companyDetailsForm.required')}</span>}
                </p>
              </div>
              <div className="form__column">
                <p>
                  <label htmlFor="city">{t('companyDetailsForm.city')}</label>
                  <input type="text" id="city" value={formData.city || ''} onChange={handleInputChange} />
                  {errors.city && <span className="error">{t('companyDetailsForm.requiredLetters')}</span>}
                </p>
                <p>
                  <label htmlFor="postal_code">{t('companyDetailsForm.zip')}</label>
                  <input type="text" id="postal_code" value={formData.postal_code || ''} onChange={handleInputChange} />
                  {errors.postal_code && <span className="error">{t('companyDetailsForm.requiredNumbers')}</span>}
                </p>
              </div>
              <div className="form__column">
                <p>
                  <label htmlFor="ico">{t('companyDetailsForm.id')}</label>
                  <input type="text" id="ico" value={formData.ico || ''} onChange={handleInputChange} />
                  {errors.ico && <span className="error">{t(`companyDetailsForm.requiredId.${country}`)}</span>}
                </p>
                <p>
                  <label htmlFor="dic">{t('companyDetailsForm.taxid')} <em>{t('companyDetailsForm.optional')}</em></label>
                  <input type="text" id="dic" value={formData.dic || ''} onChange={handleInputChange} />
                </p>
              </div>
              <div className="form__column">
                <div className="form__p">
                  <label htmlFor="country">{t('companyDetailsForm.country')}</label>
                  <div className={`country ${isCountrySelectOpen ? 'is-open' : ''}`} onClick={toggleCountry}>
                    <div className="country__selected" data-lang={country}>{t(`companyDetailsForm.countries.${country}`)}</div>
                    <ul className="country__switch">
                      {['cs', 'sk', 'hu', 'pl', 'ro'].map((lang) => (
                        <li data-lang={lang} key={lang} onClick={() => handleCountryChange(lang)}>{t(`companyDetailsForm.countries.${lang}`)}</li>
                      ))}
                    </ul>
                  </div>
                  <select name="country" id="country" className="country__select" value={country} onChange={(e) => setCountry(e.target.value)}>
                    {['cs', 'sk', 'hu', 'pl', 'ro'].map((lang) => (
                      <option value={lang} key={lang}>{t(`companyDetailsForm.countries.${lang}`)}</option>
                    ))}
                  </select>
                </div>
                <p>
                  <label htmlFor="email">{t('companyDetailsForm.email')} <em>{t('companyDetailsForm.optional')}</em></label>
                  <input type="text" id="email" value={formData.email || ''} onChange={handleInputChange} />
                </p>
              </div>
            </div>
            <div className="register__submit">
              <p className="register__back">
                <a href="#" onClick={handleBack}>{t('companyDetailsForm.back')}</a>
              </p>
              <p>
                <button className="btn" type="submit">{t('companyDetailsForm.submit')}</button>
              </p>
            </div>
          </form>
        </article>
      </section>
    </>
  );
};

export default CompanyDetailsPage;
