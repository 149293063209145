import React, {useState, useContext} from 'react';

import { AppContext } from '../context/AppContext';
import useUserDetails from '../hooks/useUserDetails';

const AppContactUs = () => {
  const [shop, setShop] = useState([]);
  const [country, setCountry] = useState('cs');
  
  useUserDetails(setShop, setCountry, "contact-us");

  return (
    <article className="app-content">
      <h1>Kontaktujte nás</h1>

      <div className="box contact contact--app">
        <p className="contact__person">
          <img
            src="/img/contact-person.png"
            alt=""
            width="120"
            height="137"
            loading="lazy"
          />
        </p>
        <p className="contact__item">
          <span className="icon icon--phone">
            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <use xlinkHref="/img/icons.svg#phone" x="0" y="0" width="100%" height="100%"></use>
            </svg>
          </span>
          <a href="tel:+420252666180">+420 252 666 180</a>
          <small>Po-Pá 9:00-15:00 hodin</small>
        </p>
        <p className="contact__item">
          <span className="icon icon--email">
            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <use xlinkHref="/img/icons.svg#email" x="0" y="0" width="100%" height="100%"></use>
            </svg>
          </span>
          <a href="mailto:info@permoniq.com">info@permoniq.com</a>
        </p>
      </div>

      <div className="box">
        <h2>Pošlete nám zprávu</h2>

        <form action="#" method="post" className="form">
          <p>
            <label htmlFor="contact-subject">Předmět:</label>
            <input type="text" id="contact-subject" defaultValue="" />
          </p>

          <p>
            <label htmlFor="contact-message">Zpráva:</label>
            <textarea id="contact-message" rows="4" cols="50"></textarea>
          </p>

          <p className="form__submit">
            <input type="submit" value="Odeslat zprávu" className="btn" />
          </p>
        </form>
      </div>
    </article>
  );
};

export default AppContactUs;
