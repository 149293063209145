import React, { useEffect } from 'react';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import Footer from './Footer';
import RegisterNavBar from "./RegisterNavBar"
import LoginNavBar from './LoginNavBar';
import AppNavBar from './AppNavBar'; // Import AppNavBar
import FirstSection from "./FirstSection";
import BenefitsSection from './BenefitsSection';
import CertifySection from './CertifySection';
import ComparisonSection from './ComparisonSection';
import SetupSection from './SetupSection';
import ForWhomSection from './ForWhomSection';
import ReferenceSection from './ReferenceSection';
import FAQSection from './FAQSection';
import AdvisioSection from './AdvisioSection';
import TryPermoniQSection from './TryPermoniqSection';
import ConsultationModal from './ConsultationModal';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import CompanyDetailsPage from './CompanyDetailsPage';
import UploadProductsPage from './UploadProductsPage';
import ConnectGooglePage from './ConnectGooglePage';
import InstallTrackingCodePage from './InstallTrackingCodePage';
import RegistrationAccountPage from './RegistrationAccountPage'; // Přidej tento import
import LoadingGoogleLoginPage from './LoadingGoogleLoginPage';
import GoogleTermsPage from './GoogleTermsPage';
import AppContent from './AppContent';

const Layout = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const location = useLocation();

  // Function to scroll to the given ID
  const scrollToId = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Detect hash change and scroll to the element
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1); // Remove the '#'
      if (hash) {
        scrollToId(hash);
      }
    };

    // Initial check
    handleHashChange();

    // Add event listener for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  // Detect clicks on links and scroll to the element
  useEffect(() => {
    const handleLinkClick = (event) => {
      const target = event.target.closest('a');
      if (target && target.hash) {
        const id = target.hash.substring(1);
        scrollToId(id);
      }
    };

    // Add event listener for link clicks
    document.addEventListener('click', handleLinkClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleLinkClick);
    };
  }, []);

  // Determine if the current page is the login, register, company details, upload products, connect google, install tracking code or registration account page
  const isLoginPage = location.pathname.includes('login');
  const isGoogleLoginPage = location.pathname.includes("auth/google/callback")
  const isRegisterPage = location.pathname.includes('app/register');
  const isCompanyDetailsPage = location.pathname.includes('app/company-details');
  const isUploadProductsPage = location.pathname.includes('app/upload-products');
  const isConnectGooglePage = location.pathname.includes('app/connect-google');
  const isInstallTrackingCodePage = location.pathname.includes('app/install-tracking-code');
  const isRegistrationAccountPage = location.pathname.includes('registration-account'); // Přidej tuto kontrolu
  const isGoogleTermsPage = location.pathname.includes("google-terms")

  const isAppCampaigns = location.pathname.includes("app/campaigns")
  const isAppStatistics = location.pathname.includes("app/statistics")
  const isAppCredit = location.pathname.includes("app/credit")
  const isAppSettings = location.pathname.includes("app/settings")
  const isAppHelp = location.pathname.includes("app/help")
  const isAppContactUs = location.pathname.includes("app/contact-us")

  const isNotFound = location.pathname.includes("404")

  return (
    <>
      {
        isGoogleLoginPage ? <LoadingGoogleLoginPage /> :
          <>
            <header className="header">
              {isLoginPage || isRegistrationAccountPage ? ( // Přidej isRegistrationAccountPage
                <LoginNavBar />
              ) : isRegisterPage || isCompanyDetailsPage || isUploadProductsPage || isConnectGooglePage || isInstallTrackingCodePage || isGoogleTermsPage || isNotFound ? ( // Přidej isRegistrationAccountPage
                <RegisterNavBar />
              ) : isAppCampaigns || isAppStatistics || isAppCredit || isAppSettings || isAppHelp || isAppContactUs ? (
                <AppNavBar />
              ) : (
                <Header />
              )
              }
            </header>
            <main className='content'>
              {isLoginPage ? (
                <LoginPage />
              ) : isRegisterPage ? (
                <RegisterPage />
              ) : isCompanyDetailsPage ? (
                <CompanyDetailsPage />
              ) : isUploadProductsPage ? (
                <UploadProductsPage />
              ) : isConnectGooglePage ? (
                <ConnectGooglePage />
              ) : isInstallTrackingCodePage ? (
                <InstallTrackingCodePage />
              ) : isRegistrationAccountPage ? (
                <RegistrationAccountPage />
              ) : isGoogleTermsPage ? (
                <GoogleTermsPage id={t('paths.googleTerms')} />
              ) : isAppCampaigns || isAppStatistics || isAppCredit || isAppSettings || isAppHelp || isAppContactUs ? (
                <AppContent />
              )
                :
                (
                  <>
                    <FirstSection id={t('paths.firstSection')} />
                    <BenefitsSection id={t('paths.benefits')} />
                    <CertifySection id={t('paths.certify')} />
                    <ComparisonSection id={t('paths.comparison')} />
                    <SetupSection id={t('paths.howToSetUp')} />
                    <ForWhomSection id={t('paths.forWhom')} />
                    <ReferenceSection id={t('paths.references')} />
                    <FAQSection id={t('paths.faq')} />
                    <AdvisioSection id={t('paths.advisio')} />
                    <TryPermoniQSection id={t('paths.tryFree')} />
                    <ConsultationModal id={t('paths.consultation')} />
                  </>
                )}
            </main>
            <footer>
              <Footer isLoginPage={isLoginPage || isRegisterPage || isCompanyDetailsPage || isUploadProductsPage || isConnectGooglePage || isInstallTrackingCodePage || isRegistrationAccountPage} />
            </footer>
          </>
      }
    </>
  );
};

export default Layout;
