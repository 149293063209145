import React, { useState } from 'react';

import useUserDetails from '../hooks/useUserDetails';

const AppStatistics = () => {
    const [shop, setShop] = useState([]);
    const [country, setCountry] = useState('cs');

    useUserDetails(setShop, setCountry, "statistics");

    return (
        <article className="app-content">
            <h1>Statistiky</h1>

            <div className="app-content__row">
                <div className="app-content__col">
                    <p>
                        <label htmlFor="campaign-type">Typy kampaní</label>
                        <select name="campaign-type" id="campaign-type">
                            <option value="Všechny kampaně">Všechny kampaně</option>
                            <option value="Všechny kampaně 2">Všechny kampaně 2</option>
                            <option value="Všechny kampaně 3">Všechny kampaně 3</option>
                        </select>
                    </p>
                </div>

                <div className="app-content__col">
                    <p>
                        <label htmlFor="campaign-date">Statistiky za období</label>
                        <select name="campaign-date" id="campaign-date">
                            <option value="Poslední měsíc">Poslední měsíc</option>
                            <option value="Poslední měsíc 2">Poslední měsíc 2</option>
                            <option value="Poslední měsíc 3">Poslední měsíc 3</option>
                        </select>
                    </p>
                </div>
            </div>

            <p>
                Exportovat do
                <a href="#" className="export">CSV</a>
                <a href="#" className="export">XLS</a>
            </p>

            <div className="app-content__row">
                <div className="info">
                    <span className="pack pack--1">
                        <span className="icon icon--grow">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#grow" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span>
                    </span>
                    <span className="info__title">Celkové výdaje</span>
                    <span className="info__value">232 345, 50 Kč</span>
                </div>

                <div className="info">
                    <span className="pack pack--2">
                        <span className="icon icon--chart">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#chart" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span>
                    </span>
                    <span className="info__title">PNO</span>
                    <span className="info__value">56, 70 %</span>
                </div>
            </div>

            <div className="app-content__row">
                <div className="info">
                    <span className="pack pack--3">
                        <span className="icon icon--money">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#money" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span>
                    </span>
                    <span className="info__title">Generovaný obrat</span>
                    <span className="info__value is-negative">-12 670 Kč</span>
                </div>

                <div className="info">
                    <span className="pack pack--4">
                        <span className="icon icon--wallet">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#wallet" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span>
                    </span>
                    <span className="info__title">Asistovaný obrat</span>
                    <span className="info__value">12 670 Kč</span>
                </div>
            </div>

            <div className="box">
                <p className="graph">
                    <span className="graph__legend">
                        <em><span className="graph__point graph__point--1"></span> Výdaje</em>
                        <em><span className="graph__point graph__point--3"></span> Obrat</em>
                    </span>

                    <span className="graph__controls">
                        <a href="#"><span className="icon icon--minus">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#minus" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span></a>
                        <a href="#"><span className="icon icon--plus">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#plus" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span></a>
                        <a href="#"><span className="icon icon--zoom">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#zoom" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span></a>
                        <a href="#"><span className="icon icon--hand">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#hand" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span></a>
                        <a href="#"><span className="icon icon--home-2">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#home-2" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span></a>
                        <a href="#"><span className="icon icon--download">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#download" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span></a>
                    </span>
                </p>
                <p><img src="/img/placeholder/graph-1.png" alt="" /></p>
            </div>

            <div className="app-content__row">
                <div className="info">
                    <span className="pack pack--5">
                        <span className="icon icon--user">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#user" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span>
                    </span>
                    <span className="info__title">Počet návštěvníků</span>
                    <span className="info__value">3 456</span>
                </div>

                <div className="info">
                    <span className="pack pack--6">
                        <span className="icon icon--click">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#click" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span>
                    </span>
                    <span className="info__title">Počet kliknutí</span>
                    <span className="info__value">6 654</span>
                </div>
            </div>

            <div className="app-content__row">
                <div className="info">
                    <span className="pack pack--7">
                        <span className="icon icon--basket">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#basket" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span>
                    </span>
                    <span className="info__title">Počet objednávek</span>
                    <span className="info__value is-negative">1 456</span>
                </div>

                <div className="info">
                    <span className="pack pack--8">
                        <span className="icon icon--converse">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#converse" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span>
                    </span>
                    <span className="info__title">Konverzní poměr</span>
                    <span className="info__value">34 %</span>
                </div>
            </div>

            <div className="box">
                <p className="graph">
                    <span className="graph__legend">
                        <em><span className="graph__point graph__point--5"></span> Počet návštěvníků</em>
                        <em><span className="graph__point graph__point--7"></span> Počet objednávek</em>
                    </span>

                    <span className="graph__controls">
                        <a href="#"><span className="icon icon--minus">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#minus" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span></a>
                        <a href="#"><span className="icon icon--plus">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#plus" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span></a>
                        <a href="#"><span className="icon icon--zoom">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#zoom" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span></a>
                        <a href="#"><span className="icon icon--hand">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#hand" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span></a>
                        <a href="#"><span className="icon icon--home-2">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#home-2" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span></a>
                        <a href="#"><span className="icon icon--download">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#download" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span></a>
                    </span>
                </p>
                <p><img src="/img/placeholder/graph-2.png" alt="" /></p>
            </div>

            <div className="box">
                <p className="graph">
                    <span className="graph__legend">
                        <em><span className="graph__point graph__point--6"></span> Počet kliknutí</em>
                    </span>

                    <span className="graph__controls">
                        <a href="#"><span className="icon icon--minus">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#minus" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span></a>
                        <a href="#"><span className="icon icon--plus">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#plus" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span></a>
                        <a href="#"><span className="icon icon--zoom">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#zoom" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span></a>
                        <a href="#"><span className="icon icon--hand">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#hand" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span></a>
                        <a href="#"><span className="icon icon--home-2">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#home-2" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span></a>
                        <a href="#"><span className="icon icon--download">
                            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <use xlinkHref="/img/icons.svg#download" x="0" y="0" width="100%" height="100%"></use>
                            </svg>
                        </span></a>
                    </span>
                </p>
                <p><img src="/img/placeholder/graph-3.png" alt="" /></p>
            </div>

            <p><a href="#" className="link">Jak se měří statistiky v PermoniQ?</a></p>
        </article>
    );
};

export default AppStatistics;
