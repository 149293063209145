import React, { useState } from 'react';

import useUserDetails from '../hooks/useUserDetails';

const AppCredit = () => {
    const [shop, setShop] = useState([]);
    const [country, setCountry] = useState('cs');

    useUserDetails(setShop, setCountry, "credit");

    return (
        <article className="app-content">
            <h1>Dobíjení kreditu</h1>

            <div className="item item--success">
                <span className="item__ico">
                    <span className="icon icon--success">
                        <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <use xlinkHref="/img/icons.svg#success" x="0" y="0" width="100%" height="100%"></use>
                        </svg>
                    </span>
                </span>
                <span className="item__title">Váš účet je aktivní.</span>
                <span className="item__control">
                    <a href="#" className="btn">Dobít kredit</a>
                </span>
            </div>

            <div className="item item--danger">
                <span className="item__ico">
                    <span className="icon icon--danger">
                        <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <use xlinkHref="/img/icons.svg#danger" x="0" y="0" width="100%" height="100%"></use>
                        </svg>
                    </span>
                </span>
                <span className="item__title">Váš účet je neaktivní, prosím dobijte si kredit.</span>
                <span className="item__control">
                    <a href="#" className="btn">Dobít kredit</a>
                </span>
            </div>
        </article>
    );
};

export default AppCredit;
