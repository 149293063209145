import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import homeIcon from '../img/icons/home.svg';
import statisticsIcon from '../img/icons/statistics.svg';
import campaignIcon from '../img/icons/campaign.svg';
import creditDarkIcon from '../img/icons/credit_dark.svg';
import productsIcon from '../img/icons/products.svg';
import settingsIcon from '../img/icons/settings.svg';
import helpIcon from '../img/icons/help.svg';
import phoneIcon from '../img/icons/phone.svg';
import { AppContext } from '../context/AppContext';

const AppMenu = () => {
  const { setAppContentType } = useContext(AppContext);
  const [activeItem, setActiveItem] = useState('campaigns'); // výchozí aktivní položka

  const handleItemClick = (contentType) => {
    setAppContentType(contentType);
    setActiveItem(contentType); // nastaví aktuálně aktivní položku
    window.scrollTo(0, 0);
  };

  return (
    <nav className="app-nav">
      <ul>
        <li>
          <Link 
            to="#" 
            onClick={() => handleItemClick('dashboard')} 
            className={activeItem === 'dashboard' ? 'is-active' : ''}
          >
            <span className="icon icon--home">
              <img src={homeIcon} alt="home" className="icon__svg" />
            </span>
            Dashboard
          </Link>
        </li>
        <li>
          <Link 
            to="#" 
            onClick={() => handleItemClick('statistics')} 
            className={activeItem === 'statistics' ? 'is-active' : ''}
          >
            <span className="icon icon--statistics">
              <img src={statisticsIcon} alt="statistics" className="icon__svg" />
            </span>
            Statistiky
          </Link>
        </li>
        <li>
          <Link 
            to="#" 
            onClick={() => handleItemClick('campaigns')} 
            className={activeItem === 'campaigns' ? 'is-active' : ''}
          >
            <span className="icon icon--campaign">
              <img src={campaignIcon} alt="campaign" className="icon__svg" />
            </span>
            Kampaně
          </Link>
        </li>
        <li>
          <Link 
            to="#" 
            onClick={() => handleItemClick('credit')} 
            className={activeItem === 'credit' ? 'is-active' : ''}
          >
            <span className="icon icon--credit">
              <img src={creditDarkIcon} alt="credit" className="icon__svg" />
            </span>
            Dobíjení kreditu
          </Link>
        </li>
        <li>
          <Link 
            to="#" 
            onClick={() => handleItemClick('products')} 
            className={activeItem === 'products' ? 'is-active' : ''}
          >
            <span className="icon icon--products">
              <img src={productsIcon} alt="products" className="icon__svg" />
            </span>
            Produkty
          </Link>
        </li>
        <li>
          <Link 
            to="#" 
            onClick={() => handleItemClick('settings')} 
            className={activeItem === 'settings' ? 'is-active' : ''}
          >
            <span className="icon icon--settings">
              <img src={settingsIcon} alt="settings" className="icon__svg" />
            </span>
            Nastavení e-shopu
          </Link>
        </li>
        <li>
          <Link 
            to="#" 
            onClick={() => handleItemClick('help')} 
            className={activeItem === 'help' ? 'is-active' : ''}
          >
            <span className="icon icon--help">
              <img src={helpIcon} alt="help" className="icon__svg" />
            </span>
            Nápověda
          </Link>
        </li>
        <li>
          <Link 
            to="#" 
            onClick={() => handleItemClick('contact-us')} 
            className={activeItem === 'contact-us' ? 'is-active' : ''}
          >
            <span className="icon icon--phone">
              <img src={phoneIcon} alt="phone" className="icon__svg" />
            </span>
            Kontaktujte nás
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default AppMenu;
