import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppContext } from '../context/AppContext';

import useUserDetails from '../hooks/useUserDetails';

import { addDangerAlert } from '../store/reducers/dangerAlertSlice';
import { updatePk, updateCustomerName, updateCurrencyCode, updateCustomerUrl, updatePropertyId, updateGoogleAdsId, shopDetailRetrieve, analyticsAccountCreate, analyticsConnectGoogleAds } from "../store/reducers/createAnalyticsSlice"
import { getUserData } from '../store/reducers/getUserInfoSlice';
import { setPk } from '../store/reducers/pkSlice';

import gtmLogo from '../img/logos/logo-gtm.png';
import shoptetLogo from '../img/logos/logo-shoptet.png';
import upgatesLogo from '../img/logos/logo-upgates.png';

const InstallTrackingCodePage = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [shop, setShop] = useState([]);
  const [country, setCountry] = useState('cs');
  const [openIndices, setOpenIndices] = useState([]);
  const [heights, setHeights] = useState({});
  const { code, setCode } = useContext(AppContext)

  const { pk, customerName, currencyCode, customerUrl, propertyId } = useSelector(state => state.createAnalytics)

  const answerRefs = useRef([]);
  const copyButtonRef = useRef(null);

  const urlID = window.location.pathname.split("/").slice(-2, -1)[0];


  let gtmSteps = t('installTrackingCodeForm.gtmSteps', { returnObjects: true });
  let shoptetSteps = t('installTrackingCodeForm.shoptetSteps', { returnObjects: true });
  let upgatesSteps = t('installTrackingCodeForm.upgatesSteps', { returnObjects: true });

  gtmSteps = Array.isArray(gtmSteps) ? gtmSteps : [];
  shoptetSteps = Array.isArray(shoptetSteps) ? shoptetSteps : [];
  upgatesSteps = Array.isArray(upgatesSteps) ? upgatesSteps : [];

  // useEffect(() => {
  //   const userDetails = async () => {
  //     try {
  //       const data = await dispatch(getUserData()).unwrap();
  //       if (data) {
  //         setShop(data);
  //         if (data.uuid) {
  //           dispatch(setPk(data.uuid));
  //           navigate(`/${lng}/app/install-tracking-code/${data.uuid}/`);
  //         }
  //       } else {
  //         navigate(`/${lng}/app/install-tracking-code/`);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching user details:', error);
  //       navigate(`/404/`);
  //     }
  //   };

  //   userDetails();

  //   if (lng === 'en') {
  //     setCountry('cs');
  //   } else {
  //     setCountry(lng);
  //   }
  // }, [dispatch, navigate]);

  useUserDetails(setShop, setCountry, "install-tracking-code")

  useEffect(() => {
    dispatch(updatePk(urlID))

    const fetchData = async () => {
      const resultAction = await dispatch(shopDetailRetrieve(urlID));
      if (shopDetailRetrieve.fulfilled.match(resultAction)) {
        console.log(resultAction)
        dispatch(updateCurrencyCode(resultAction.payload.currency));
        dispatch(updateCustomerName(resultAction.payload.name))
        dispatch(updateCustomerUrl(resultAction.payload.website))
        dispatch(updateGoogleAdsId(resultAction.payload.google_ads))
        console.log('Fetched data successfully', resultAction.payload);
      } else {
        console.error('Failed to fetch data', resultAction.payload);
      }
    };
    fetchData();
  }, [urlID, dispatch]);


  useEffect(() => {
    const newHeights = {};
    answerRefs.current.forEach((ref, index) => {
      if (ref) {
        newHeights[index] = ref.scrollHeight;
      }
    });
    setHeights(newHeights);
  }, []);

  useEffect(() => {
    setHeights((prevHeights) => {
      const newHeights = { ...prevHeights };
      openIndices.forEach((index) => {
        if (answerRefs.current[index]) {
          newHeights[index] = answerRefs.current[index].scrollHeight;
        }
      });
      return newHeights;
    });
  }, [openIndices]);

  const handleToggle = (index) => {
    setOpenIndices((prevIndices) =>
      prevIndices.includes(index)
        ? prevIndices.filter((i) => i !== index)
        : [...prevIndices, index]
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!code.trim()) {
      dispatch(addDangerAlert({ id: Date.now(), title: 'Error', message: 'Please add the tracking code', isOpen: true }));
      return;
    }
    window.scrollTo(0, 0);
    // navigate(`/${lng}/app/next-page/${urlID}/`); // Adjust this to your next page
  };

  const handleBack = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    navigate(`/${lng}/app/connect-google/${urlID}/`);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(code).then(() => {
      dispatch(addDangerAlert({ id: Date.now(), title: 'Success', message: 'Code copied to clipboard', isOpen: true }));
      copyButtonRef.current.blur();
    });
  };

  const handleNavigation = (step) => {
    const steps = [
      '/app/register',
      '/app/company-details',
      '/app/upload-products',
      '/app/connect-google',
      '/app/install-tracking-code'
    ];
    navigate(`/${lng}${steps[step]}/${urlID}/`);
  };

  const handleFinish = async () => {
    console.log(currencyCode);
    console.log(customerName);
    console.log(customerUrl);

    const resultAction = await dispatch(analyticsAccountCreate());
    if (analyticsAccountCreate.fulfilled.match(resultAction)) {
      console.log('Analytics account created successfully', resultAction.payload);
      dispatch(updatePropertyId(resultAction.payload.propertyId));

      if (resultAction.payload.propertyId) {
        const connectGA4andAds = await dispatch(analyticsConnectGoogleAds());
        if (analyticsConnectGoogleAds.fulfilled.match(connectGA4andAds)) {
          console.log('GA4 and Google Ads connected successfully');
          navigate(`/${lng}/app/campaigns/`)
        } else {
          console.error('Failed to connect GA4 and Google Ads', connectGA4andAds.payload);
        }
      }
    } else {
      console.error('Failed to create analytics account', resultAction.payload);
    }
  };

  return (
    <>
      <div className="progress">
        <div className="container">
          <ul>
            <li onClick={() => handleNavigation(0)} className="is-checked"><strong>1.</strong> {t('installTrackingCodeProgress.addShop')}</li>
            <li onClick={() => handleNavigation(1)} className="is-checked"><strong>2.</strong> {t('installTrackingCodeProgress.companyDetails')}</li>
            <li onClick={() => handleNavigation(2)} className="is-checked"><strong>3.</strong> {t('installTrackingCodeProgress.uploadProducts')}</li>
            <li onClick={() => handleNavigation(3)} className="is-checked"><strong>4.</strong> {t('installTrackingCodeProgress.connectGoogle')}</li>
            <li onClick={() => handleNavigation(4)} className="is-active"><strong>5.</strong> {t('installTrackingCodeProgress.installCode')}</li>
          </ul>
        </div>
      </div>

      {/* <DangerAlerts /> */}

      <section className="section register">
        <article className="container">
          <h1 className="title">{t('installTrackingCodeTitle')}</h1>
          <form action="#" method="post" className="form" onSubmit={handleSubmit}>
            <div className="box">
              <p>
                <label htmlFor="code">{t('installTrackingCodeForm.codeInstructions')}</label>
                <textarea
                  name="code"
                  id="code"
                  className="form__code"
                  cols="30"
                  rows="4"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </p>
              <p>
                <button className="btn js-copy" type="button" onClick={copyToClipboard} ref={copyButtonRef}>{t('installTrackingCodeForm.copyCode')}</button>
              </p>

              <br />

              <p>{t('installTrackingCodeForm.helpTitle')}</p>

              <div className="faq faq--compact">
                <div className={`faq__item ${openIndices.includes(0) ? 'is-open' : ''}`}>
                  <h3 className="faq__question" onClick={() => handleToggle(0)}>
                    <img src={gtmLogo} alt={t('installTrackingCodeForm.gtmTitle')} />
                  </h3>
                  <div
                    className="faq__answer"
                    ref={(el) => (answerRefs.current[0] = el)}
                    style={{ maxHeight: openIndices.includes(0) ? `${heights[0]}px` : '0' }}
                  >
                    <ol>
                      {gtmSteps.map((step, index) => (
                        <li key={index}>{step}</li>
                      ))}
                    </ol>
                  </div>
                </div>

                <div className={`faq__item ${openIndices.includes(1) ? 'is-open' : ''}`}>
                  <h3 className="faq__question" onClick={() => handleToggle(1)}>
                    <img src={shoptetLogo} alt={t('installTrackingCodeForm.shoptetTitle')} />
                  </h3>
                  <div
                    className="faq__answer"
                    ref={(el) => (answerRefs.current[1] = el)}
                    style={{ maxHeight: openIndices.includes(1) ? `${heights[1]}px` : '0' }}
                  >
                    <ol>
                      {shoptetSteps.map((step, index) => (
                        <li key={index}>{step}</li>
                      ))}
                    </ol>
                  </div>
                </div>

                <div className={`faq__item ${openIndices.includes(2) ? 'is-open' : ''}`}>
                  <h3 className="faq__question" onClick={() => handleToggle(2)}>
                    <img src={upgatesLogo} alt={t('installTrackingCodeForm.upgatesTitle')} />
                  </h3>
                  <div
                    className="faq__answer"
                    ref={(el) => (answerRefs.current[2] = el)}
                    style={{ maxHeight: openIndices.includes(2) ? `${heights[2]}px` : '0' }}
                  >
                    <ol>
                      {upgatesSteps.map((step, index) => (
                        <li key={index}>{step}</li>
                      ))}
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="register__submit">
              <p className="register__back">
                <a href="#" onClick={handleBack}>{t('installTrackingCodeForm.back')}</a>
              </p>
              <p>
                <button className="btn" type="submit" onClick={handleFinish}>{t('installTrackingCodeForm.finish')}</button>
              </p>
            </div>
          </form>
        </article>
      </section>
    </>
  );
};

export default InstallTrackingCodePage;
