import { createSlice } from '@reduxjs/toolkit';

const successAlertSlice = createSlice({
  name: 'successAlerts',
  initialState: [],
  reducers: {
    addSuccessAlert: (state, action) => {
      state.push(action.payload);
    },
    closeSuccessAlert: (state, action) => {
      return state.filter(alert => alert.id !== action.payload);
    }
  }
});

export const { addSuccessAlert, closeSuccessAlert } = successAlertSlice.actions;
export default successAlertSlice.reducer;
