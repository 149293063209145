import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openModal } from '../store/reducers/modalSlice';
import he from 'he';
import googleLogo from "../img/login-google.svg";
import facebookLogo from "../img/login-facebook.svg";
import contactPerson from "../img/contact-person.png";
import icons from '../img/icons.svg'; // Importujte soubor SVG s ikonami
import AxiosInstance from './AxiosConfig'; // Importujte Axios konfiguraci
import { useNavigate, useParams } from 'react-router-dom'; // Importujte useNavigate
import Cookies from 'js-cookie'; // Importujte js-cookie

const renderHTML = (html) => {
  return { __html: he.decode(html) };
};

const TryPermoniQSection = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password2Visible, setPassword2Visible] = useState(false);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(false);
  const [isPasswordLettersValid, setIsPasswordLettersValid] = useState(false);
  const [isPasswordNumberValid, setIsPasswordNumberValid] = useState(false);
  const [doPasswordsMatch, setDoPasswordsMatch] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleOpenModal = () => {
    dispatch(openModal());
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    AxiosInstance.post('user/create/', {
      email: email,
      password: password,
      login_provider: "own"
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          const { access, refresh } = response.data;

          // Nastavení secure cookies s životností 7 dní
          Cookies.set("aTkn_0yD9K", access, { secure: true, sameSite: 'None', expires: 1 });
          Cookies.set("rTkn_4zF7P", refresh, { secure: true, sameSite: 'None', expires: 7 });

          window.scrollTo(0, 0);

          navigate(`/${lng}/app/register`); // Přesměrování na stránku /upload-products po úspěšném vytvoření účtu
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert('An error occurred. Please try again.');
      });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const togglePassword2Visibility = () => {
    setPassword2Visible(!password2Visible);
  };

  useEffect(() => {
    setIsPasswordLengthValid(password.length >= 8);
    setIsPasswordLettersValid(/[a-z]/.test(password) && /[A-Z]/.test(password));
    setIsPasswordNumberValid(/\d/.test(password));
    setDoPasswordsMatch(password !== '' && password2 !== '' && password === password2);
  }, [password, password2]);

  return (
    <section className="section" id={t('paths.tryFree')}>
      <article className="container">
        <h2 className="title" dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.title'))}></h2>
        <div className="contact">
          <div className="box contact__1">
            <form action="#" method="post" className="form form--register" onSubmit={handleSubmit}>
              <p>
                <label htmlFor="email" dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.emailLabel'))}></label>
                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
              </p>
              <div className="form__column">
                <p className="form__password">
                  <label htmlFor="password" dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.passwordLabel'))}></label>
                  <input
                    type={passwordVisible ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span className="icon icon--eye" onClick={togglePasswordVisibility}>
                    <svg className="icon__svg">
                      <use xlinkHref={`${icons}#eye`} x="0" y="0" width="100%" height="100%"></use>
                    </svg>
                  </span>
                </p>
                <p className="form__password">
                  <label htmlFor="password-2" dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.passwordRepeatLabel'))}></label>
                  <input
                    type={password2Visible ? "text" : "password"}
                    id="password-2"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    required
                  />
                  <span className="icon icon--eye" onClick={togglePassword2Visibility}>
                    <svg className="icon__svg">
                      <use xlinkHref={`${icons}#eye`} x="0" y="0" width="100%" height="100%"></use>
                    </svg>
                  </span>
                </p>
              </div>
              <div className="form__column">
                <ul className="form__check">
                  <li className={isPasswordLengthValid ? 'is-checked' : ''} dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.passwordCriteria.length'))}></li>
                  <li className={isPasswordLettersValid ? 'is-checked' : ''} dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.passwordCriteria.letters'))}></li>
                  <li className={isPasswordNumberValid ? 'is-checked' : ''} dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.passwordCriteria.number'))}></li>
                </ul>
                <ul className="form__check">
                  <li className={doPasswordsMatch ? 'is-checked' : ''} dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.passwordCriteria.match'))}></li>
                </ul>
              </div>
              <p>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="gdpr"
                    id="gdpr"
                    checked={isCheckboxChecked}
                    onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                    required
                  />
                  <span className="checkbox__label" dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.gdprLabel'))}></span>
                  <span className="checkbox__checker"></span>
                </label>
              </p>
              <p className="form__submit">
                <input type="submit" value={t('tryFreeSection.form.submitButton')} className="btn" disabled={!isCheckboxChecked} />
                <em dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.orContinueWith'))}></em>
                <span className="form__login">
                  <a href="#"><img src={googleLogo} alt="Google" /></a>
                  <a href="#"><img src={facebookLogo} alt="Facebook" /></a>
                </span>
              </p>
            </form>
          </div>
          <div className="box is-connected contact__2">
            <ul className="list">
              <li dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.benefits.freeMonth'))}></li>
              <li dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.benefits.easySetup'))}></li>
              <li dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.benefits.simpleEvaluation'))}></li>
              <li dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.benefits.supportTeam'))}></li>
            </ul>
          </div>
          <div className="box box--back contact__3">
            <p className="contact__person"><img src={contactPerson} alt="" width="120" height="137" loading="lazy" /></p>
            <p className="contact__item">
              <span className="icon icon--phone">
                <svg className="icon__svg">
                  <use xlinkHref={`${icons}#phone`} x="0" y="0" width="100%" height="100%"></use>
                </svg>
              </span>
              <a href="tel:+420252666180">+420 252 666 180</a>
              <small dangerouslySetInnerHTML={renderHTML(t('footerSection.contacts.phoneHours'))}></small>
            </p>
            <p className="contact__item">
              <span className="icon icon--email">
                <svg className="icon__svg">
                  <use xlinkHref={`${icons}#email`} x="0" y="0" width="100%" height="100%"></use>
                </svg>
              </span>
              <a href="mailto:info@permoniq.com">info@permoniq.com</a>
            </p>
            <p><a className="btn js-modal" dangerouslySetInnerHTML={renderHTML(t('tryFreeSection.form.consultationButton'))} onClick={handleOpenModal}></a></p>
          </div>
        </div>
      </article>
    </section>
  );
};

export default TryPermoniQSection;
