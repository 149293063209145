import { createSlice } from '@reduxjs/toolkit';

const infoAlertSlice = createSlice({
  name: 'infoAlerts',
  initialState: [],
  reducers: {
    addInfoAlert: (state, action) => {
      state.push(action.payload);
    },
    closeInfoAlert: (state, action) => {
      return state.filter(alert => alert.id !== action.payload);
    }
  }
});

export const { addInfoAlert, closeInfoAlert } = infoAlertSlice.actions;
export default infoAlertSlice.reducer;
