import { useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUserData } from "../store/reducers/getUserInfoSlice";
import { setPk } from "../store/reducers/pkSlice";

import { AppContext } from "../context/AppContext";

/**
 * Custom hook for fetching user details and navigating based on the data.
 * 
 * @param {Function} setShop - It is necessary to create useState for shop variable and use it as an argument.
 * @param {Function} setCountry - It is necessary to create useState for country variable and use it as an argument.
 * @param {Function} path - Set path so the hook will know where to navigate. 
 */
const useUserDetails = (setShop, setCountry, path) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()
    const { lng } = useParams();
    const { i18n } = useTranslation();
    const {
        userData,
        setUserData,
        selectedShop,
        setIsPmaxActive,
        setIsCampaignCreated,
        setHasItems,
        setCampaignAssetsObject,
        setIsCampaignDeactivated,
        setIsUserDataLoading,
        setPno,
        setShopName,
        setMerchantId,
        setCampaignId,
        setBudget,
        setCampaignBudget
    } = useContext(AppContext)

    useEffect(() => {
        const userDetails = async () => {
            try {
                setIsUserDataLoading(true)
                const { shop, data } = await dispatch(getUserData()).unwrap(); // Destructure shop and data
                setUserData(data)
                setIsUserDataLoading(false)
                console.log(data)
                if (selectedShop) {

                    setShopName(selectedShop.name)
                    setPno(selectedShop.pno)
                    setMerchantId(selectedShop.google_merchant)
                    setCampaignId(selectedShop.campaign[0].campaign_id)
                    setBudget(selectedShop.budget)
                    setCampaignBudget(selectedShop.campaign[0].budget)
                    

                    if (selectedShop.campaign.length > 0) {
                        setIsCampaignCreated(true)
                        setCampaignAssetsObject({
                            shop_id: selectedShop.uuid,
                            asset_id: selectedShop.campaign[0].addsets[0].asset_id,
                            campaign_id: selectedShop.campaign[0].campaign_id,
                            items: [
                                {
                                    name: selectedShop.name,
                                    type: "BUSINESS_NAME"
                                }
                            ]
                        });
                        if (selectedShop.campaign[0].campaign_type === "google-pmax" && selectedShop.campaign[0].campaign_status === "active") {
                            setIsCampaignDeactivated(false)
                            setIsPmaxActive(true)

                        } else if (selectedShop.campaign[0].campaign_type === "google-pmax" && selectedShop.campaign[0].campaign_status === "deactived") {
                            setIsCampaignDeactivated(true)
                        } else {
                            setIsPmaxActive(false)
                        }

                        if (selectedShop.campaign[0].addsets[0].has_items) {
                            setHasItems(true)
                            setIsCampaignDeactivated(false)
                        } else {
                            setHasItems(false)
                        }

                    } else {
                        setIsCampaignCreated(false)
                        setIsPmaxActive(false)
                    }

                    setShop(selectedShop); // Set shop state with the returned shop object

                    if (selectedShop.uuid) {
                        console.log(selectedShop)
                        dispatch(setPk(selectedShop.uuid)); // Use shop.uuid for setting PK
                        navigate(`/${lng}/app/${path}/${selectedShop.uuid}/`);
                    }
                } else if (shop && !location.pathname.includes("/register/")) {

                    setShopName(shop.name)
                    setPno(shop.pno)
                    setMerchantId(shop.google_merchant)
                    setCampaignId(shop.campaign[0].campaign_id)
                    setBudget(shop.budget)
                    setCampaignBudget(shop.campaign[0].budget)

                    if (shop.campaign.length > 0) {
                        setIsCampaignCreated(true)
                        setIsCampaignDeactivated(true)
                        setCampaignAssetsObject({
                            shop_id: shop.uuid,
                            asset_id: shop.campaign[0].addsets[0].asset_id,
                            campaign_id: shop.campaign[0].campaign_id,
                            items: [
                                {
                                    name: shop.name,
                                    type: "BUSINESS_NAME"
                                }
                            ]
                        });
                        if (shop.campaign[0].campaign_type === "google-pmax" && shop.campaign[0].campaign_status === "active") {
                            setIsPmaxActive(true)
                            setIsCampaignDeactivated(false)
                        } else if (shop.campaign[0].campaign_type === "google-pmax" && shop.campaign[0].campaign_status === "deactivated") {
                            setIsCampaignDeactivated(true)
                        } else {
                            setIsPmaxActive(false)
                        }

                        if (shop.campaign[0].addsets[0].has_items) {
                            setIsCampaignDeactivated(false)
                            setHasItems(true)
                        } else {
                            setHasItems(false)
                        }

                    } else {
                        setIsCampaignCreated(false)
                        setIsPmaxActive(false)
                    }

                    setShop(shop); // Set shop state with the returned shop object

                    if (shop.uuid) {
                        dispatch(setPk(shop.uuid)); // Use shop.uuid for setting PK
                        navigate(`/${lng}/app/${path}/${shop.uuid}/`);
                    }
                } else {
                    navigate(`/${lng}/app/${path}/`);
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
                console.log(location)
                // navigate(`/404/`);
            }
        };

        userDetails();

        if (lng === 'en') {
            setCountry('cs');
        } else {
            setCountry(lng);
        }
    }, [dispatch, navigate, lng, setShop, setCountry, i18n, selectedShop]);
}

export default useUserDetails;
