import { createSlice } from '@reduxjs/toolkit';

const baseAlertSlice = createSlice({
  name: 'baseAlerts',
  initialState: [],
  reducers: {
    addBaseAlert: (state, action) => {
      state.push(action.payload);
    },
    closeBaseAlert: (state, action) => {
      return state.filter(alert => alert.id !== action.payload);
    }
  }
});

export const { addBaseAlert, closeBaseAlert } = baseAlertSlice.actions;
export default baseAlertSlice.reducer;
