// stepsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  steps: [true, false, false, false, false] // Assume the first step is always completed
};

const stepsSlice = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    setStepCompleted: (state, action) => {
      state.steps[action.payload.index] = action.payload.value;
    }
  }
});

export const { setStepCompleted } = stepsSlice.actions;
export default stepsSlice.reducer;
