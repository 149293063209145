import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import useUserDetails from '../hooks/useUserDetails';
import { AppContext } from '../context/AppContext';

import { updateForm } from '../store/reducers/formSlice';
import { setPk } from '../store/reducers/pkSlice';

import axiosInstance from './AxiosConfig';
import Cookies from 'js-cookie';

import csFlag from "../img/flags/cs.png";
import enFlag from "../img/flags/en.png";
import skFlag from "../img/flags/sk.png";
import plFlag from "../img/flags/pl.png";
import huFlag from "../img/flags/hu.png";
import roFlag from "../img/flags/ro.png";

const flags = {
  cs: csFlag,
  en: enFlag,
  sk: skFlag,
  pl: plFlag,
  hu: huFlag,
  ro: roFlag,
};

const phonePrefixes = {
  cs: '+420',
  sk: '+421',
  pl: '+48',
  hu: '+36',
  ro: '+40'
};

const RegisterPage = () => {
  const [isUrlValid, setIsUrlValid] = useState(true);
  const [showErrors, setShowErrors] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [isCountryOpen, setIsCountryOpen] = useState(false);
  const [shop, setShop] = useState([]);
  const [country, setCountry] = useState('cs');
  const { userData, setUserData } = useContext(AppContext)

  const { t } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formData = useSelector((state) => state.form);
  const pk = useSelector((state) => state.pk);

  useUserDetails(setShop, setCountry, "register")

  useEffect(() => {
    console.log(document.referrer)
  })

  useEffect(() => {
    if (Object.keys(shop).length > 0) {
      dispatch(updateForm({ field: "url", value: shop.website }));
      dispatch(updateForm({ field: "phone", value: shop.phone }));
      dispatch(updateForm({ field: "name", value: shop.first_name }));
      dispatch(updateForm({ field: "surname", value: shop.last_name }));
      dispatch(updateForm({ field: "country", value: shop.country }));
      handleCountryChange(shop.country);
    }
  }, [shop, dispatch]);

  const isIpAddress = (hostname) => {
    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipRegex.test(hostname);
  };

  const validateAndCleanUrl = (url) => {
    try {
      if (!url.startsWith("http")) {
        url = `https://${url}`;
      }
      const urlObj = new URL(url);
      if (isIpAddress(urlObj.hostname)) {
        return false;
      }
      if (urlObj.hostname.includes('shoptet') || urlObj.hostname.includes('upgates')) {
        return false;
      }
      const hostnameParts = urlObj.hostname.split('.');
      if (hostnameParts.length >= 2) {
        const tld = hostnameParts.slice(-2).join('.');
        return `https://${hostnameParts.join('.')}`;
      }
    } catch (error) {
      return false;
    }
    return false;
  };

  const validatePhone = (phone) => {
    phone = phone.replace(/\s+/g, ''); // Remove spaces
    if (!/^\+\d+$/.test(phone)) {
      setPhoneError(t('registerForm.invalidPhoneChars'));
      return false;
    }
    for (const [key, value] of Object.entries(phonePrefixes)) {
      if (phone.startsWith(value)) {
        setPhoneError('');
        return true;
      }
    }
    setPhoneError(t('registerForm.invalidPhonePrefix'));
    return false;
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === 'phone') {
      validatePhone(value);
    }
    dispatch(updateForm({ field: id, value }));
  };

  const handleUrlBlur = () => {
    if (formData.url) {
      const cleanedUrl = validateAndCleanUrl(formData.url);
      if (cleanedUrl) {
        setIsUrlValid(true);
        dispatch(updateForm({ field: 'url', value: cleanedUrl }));
      } else {
        setIsUrlValid(false);
      }
    } else {
      setIsUrlValid(true);
    }
  };

  const toggleCountry = () => {
    setIsCountryOpen(!isCountryOpen);
  };

  const handleCountryChange = (lang) => {
    setCountry(lang);
    setIsCountryOpen(false); // Close the dropdown after selecting a country
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowErrors(true);

    if (formData.url && formData.phone && formData.name && formData.surname && isUrlValid && !phoneError) {
      const determineCurrency = (country) => {
        return country === "cs" ? "CZK" :
          country === "hu" ? "HUF" :
            country === "sk" ? "EUR" :
              country === "ro" ? "RON" :
                country === "pl" ? "PLN" :
                  "CZK"; // Default value if needed
      };

      const firstStepRegistration = {
        website: formData.url,
        phone: formData.phone.replace(/\s+/g, ""),
        first_name: formData.name,
        last_name: formData.surname,
        country: country,
        currency: determineCurrency(country) // Use return value from function
      };

      console.log('Sending registration data:', firstStepRegistration);
      if (pk) {
        axiosInstance.patch(`shop/update/${pk}/`, firstStepRegistration)
          .then(response => {
            console.log('Registration successful:', response.data);
            window.scrollTo(0, 0);
            navigate(`/${lng}/app/company-details/${pk}`);
          })
          .catch(error => {
            console.error('Error response:', error.response?.data || error.message);
            alert(t('registerForm.registrationFailed'));
          });
      } else {
        axiosInstance.post('shop/create/', firstStepRegistration)
          .then(response => {
            console.log('Registration successful:', response.data);
            const pk = response.data.pk;
            dispatch(setPk(pk)); // Save the pk to the global state
            window.scrollTo(0, 0);
            navigate(`/${lng}/app/company-details/${pk}`);
          })
          .catch(error => {
            console.error('Error response:', error.response?.data || error.message);
            alert(t('registerForm.registrationFailed'));
          });
      }
    } else {
      alert(t('registerForm.fillAllFields'));
    }
  };

  const handleNavigation = (path) => {
    navigate(`/${lng}/app/${path}/${pk}`);
  };

  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.preventDefault();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const paste = (event.clipboardData || window.clipboardData).getData('text');
    const sanitizedText = paste.replace(/\s+/g, '');
    dispatch(updateForm({ field: 'phone', value: sanitizedText }));
  };

  return (
    <>
      <div className="progress">
        <div className="container">
          <ul>
            <li className="is-active" onClick={() => handleNavigation('register')}><strong>1.</strong> {t('registerProgress.addShop')}</li>
            <li onClick={() => handleNavigation('company-details')}><strong>2.</strong> {t('registerProgress.companyDetails')}</li>
            <li onClick={() => handleNavigation('upload-products')}><strong>3.</strong> {t('registerProgress.uploadProducts')}</li>
            <li onClick={() => handleNavigation('connect-google')}><strong>4.</strong> {t('registerProgress.connectGoogle')}</li>
            <li onClick={() => handleNavigation('install-tracking-code')}><strong>5.</strong> {t('registerProgress.installCode')}</li>
          </ul>
        </div>
      </div>

      <section className="section register">
        <article className="container">
          <h1 className="title">{t('registerTitle')}</h1>
          <form action="#" method="post" className="form" onSubmit={handleSubmit}>
            <div className="box">
              <p>
                <label htmlFor="url">{t('registerForm.url')}</label>
                <input
                  type="text"
                  id="url"
                  value={formData.url || ''} // Ensure the value is not undefined
                  onChange={handleInputChange}
                  onBlur={handleUrlBlur}
                  required
                />
                {!isUrlValid && formData.url && showErrors && <span className="error">{t('registerForm.invalidUrl')}</span>}
                {showErrors && !formData.url && <span className="error">{t('registerForm.fillAllFields')}</span>}
              </p>

              <div className="form__column">
                <div className="form__p">
                  <label htmlFor="country">{t('registerForm.country')}</label>
                  <div className={`country ${isCountryOpen ? 'is-open' : ''}`} onClick={toggleCountry}>
                    <div className="country__selected" data-lang={country}>{t(`registerForm.countries.${country}`)}</div>
                    <ul className="country__switch">
                      {['cs', 'sk', 'hu', 'pl', 'ro'].map((lang) => (
                        <li data-lang={lang} key={lang} onClick={() => handleCountryChange(lang)}>{t(`registerForm.countries.${lang}`)}</li>
                      ))}
                    </ul>
                  </div>
                  <select
                    name="country"
                    id="country"
                    className="country__select"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    {['cs', 'sk', 'hu', 'pl', 'ro'].map((lang) => (
                      <option value={lang} key={lang}>{t(`registerForm.countries.${lang}`)}</option>
                    ))}
                  </select>
                </div>
                <p>
                  <label htmlFor="phone">{t('registerForm.phone')}</label>
                  <input
                    type="tel"
                    id="phone"
                    value={formData.phone || ''} // Ensure the value is not undefined
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onPaste={handlePaste}
                    required
                  />
                  {showErrors && phoneError && <span className="error">{phoneError}</span>}
                  {showErrors && !formData.phone && <span className="error">{t('registerForm.fillAllFields')}</span>}
                </p>
              </div>

              <div className="form__column">
                <p>
                  <label htmlFor="name">{t('registerForm.name')}</label>
                  <input
                    type="text"
                    id="name"
                    value={formData.name || ''} // Ensure the value is not undefined
                    onChange={handleInputChange}
                    required
                  />
                  {showErrors && !formData.name && <span className="error">{t('registerForm.fillAllFields')}</span>}
                </p>
                <p>
                  <label htmlFor="surname">{t('registerForm.surname')}</label>
                  <input
                    type="text"
                    id="surname"
                    value={formData.surname || ''} // Ensure the value is not undefined
                    onChange={handleInputChange}
                    required
                  />
                  {showErrors && !formData.surname && <span className="error">{t('registerForm.fillAllFields')}</span>}
                </p>
              </div>
            </div>

            <div className="register__submit">
              <p className="register__back">&nbsp;</p>
              <p>
                <button type="submit" className="btn">{t('registerForm.submit')}</button>
              </p>
            </div>
          </form>
        </article>
      </section>
    </>
  );
};

export default RegisterPage;
