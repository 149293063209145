import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie'; // Importujte js-cookie
import permoniqLogo from "../img/logo-inverse.svg";
import csFlag from "../img/flags/cs.png";
import enFlag from "../img/flags/en.png";
import skFlag from "../img/flags/sk.png";
import plFlag from "../img/flags/pl.png";
import huFlag from "../img/flags/hu.png";
import roFlag from "../img/flags/ro.png";
import loginIcon from "../img/icons/login.svg"; // Importujte ikonu pro login

const flags = {
  cs: csFlag,
  en: enFlag,
  sk: skFlag,
  pl: plFlag,
  hu: huFlag,
  ro: roFlag,
};

const RegisterNavBar = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const lng = params.lng || 'cs'; // Nastavte výchozí jazyk, např. 'en'
  const navigate = useNavigate();
  const location = useLocation();
  const [isLanguageSwitchVisible, setIsLanguageSwitchVisible] = useState(false);
  const languageRef = useRef(null);

  const handleLanguageChange = (language) => {
    const currentPath = location.pathname.replace(`/${lng}`, `/${language}`);
    i18n.changeLanguage(language.toLowerCase());
    navigate(currentPath);
    setIsLanguageSwitchVisible(false); // Hide the language switcher after selecting a language
  };

  const toggleLanguageSwitch = (e) => {
    e.preventDefault();
    setIsLanguageSwitchVisible(!isLanguageSwitchVisible);
  };

  const handleClickOutside = (event) => {
    if (languageRef.current && !languageRef.current.contains(event.target)) {
      setIsLanguageSwitchVisible(false);
    }
  };

  const handleLogout = (e) => {
    e.preventDefault();
    Cookies.remove("aTkn_0yD9K", { secure: true, sameSite: 'None' });
    Cookies.remove("rTkn_4zF7P", { secure: true, sameSite: 'None' });
    navigate(`/${lng}/app/login`);
  };

  const handleHomeClick = () => {
    if (window.location.pathname.includes("google-terms") ||
      window.location.pathname.includes("privacy-policy") ||
      window.location.pathname.includes("terms-of-service")) {
      navigate(`/${Cookies.get("lng")}/`)
    } else {
      navigate(`/${lng.toLowerCase()}/`)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    console.log('Language:', lng);
  }, [lng]);

  if (!flags[lng.toLowerCase()]) {
    console.error('Invalid language:', lng);
    return null; // Pokud je jazyk neplatný, nezobrazí nic
  }

  return (
    <div className="container">
      <div className="header__logo">
        <a onClick={() => handleHomeClick()} title={t('loginNav.home')}>
          <img src={permoniqLogo} alt="PermoniQ" width="151" height="32" />
        </a>
      </div>
      <nav className="app-nav-header">
        <div className="language" ref={languageRef}>
          <a href="" onClick={toggleLanguageSwitch}>
            <img src={flags[lng.toLowerCase()]} alt={t(`loginLanguages.${lng.toLowerCase()}`)} />
          </a>
          <div className={`language__switch ${isLanguageSwitchVisible ? 'is-visible' : ''}`}>
            {Object.keys(flags).map((lang) => (
              <a href="" onClick={() => handleLanguageChange(lang.toLowerCase())} key={lang}>
                <img src={flags[lang.toLowerCase()]} alt={t(`loginLanguages.${lang.toLowerCase()}`)} /> {t(`loginLanguages.${lang.toLowerCase()}`)}
              </a>
            ))}
          </div>
        </div>
        <ul>
          <li>
            <a href="#" onClick={handleLogout}>
              <span className="icon icon--login">
                <img src={loginIcon} alt="login" className="icon__svg" />
              </span>
              {t('registerNav.logout')}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default RegisterNavBar;
