import React, { createContext, useState } from "react";
export const AppContext = createContext()

const AppProvider = ({ children }) => {
    const [userData, setUserData] = useState({})
    const [isUserDataLoading, setIsUserDataLoading] = useState(false)
    const [pk, setPk] = useState("")
    const [createCampaignObject, setcreateCampaignObject] = useState({})
    const [campaignAssetsObject, setCampaignAssetsObject] = useState({})
    const [selectedShop, setSelectedShop] = useState("")
    const [isPmaxActive, setIsPmaxActive] = useState(true)
    const [isCampaignDeactivated, setIsCampaignDeactivated] = useState(false)
    const [isCampaignCreated, setIsCampaignCreated] = useState(false)
    const [hasItems, setHasItems] = useState(false)
    const [selectedImages, setSelectedImages] = useState([])
    const [selectedLogos, setLogos] = useState([])
    const [headline, setHeadline] = useState([])
    const [longHeadline, setLongHeadline] = useState(["", "", "", "", ""])
    const [shortDescPart1, setShortDescPart1] = useState("")
    const [shortDescPart2, setShortDescPart2] = useState("")
    const [descs, setDescs] = useState(["", "", "", ""])
    const [appContentType, setAppContentType] = useState("campaigns")
    const [pno, setPno] = useState(20)
    const [shopName, setShopName] = useState("")
    const [merchantId, setMerchantId] = useState("")
    const [campaignId, setCampaignId] = useState("")
    const [budget, setBudget] = useState(0)
    const [campaignBudget, setCampaignBudget] = useState(0)

    const [code, setCode] = useState(`<!-- Tanganica pixel -->
        <script>
          (function(w,d,s,l,i){
            w[l]=w[l]||[];
            w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
            j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-TRS5RSN');
        </script>
        <!-- End Tanganica pixel -->
      `);

    const deleteAll = () => {
        setSelectedImages([])
        setLogos([])
        setHeadline([])
        setLongHeadline(["", "", "", "", ""])
        setShortDescPart1("")
        setShortDescPart2("")
        setDescs(["", "", "", ""])
    }

    return (
        <AppContext.Provider value={{
            userData,
            setUserData,
            isUserDataLoading,
            setIsUserDataLoading,
            pk,
            setPk,
            createCampaignObject,
            setcreateCampaignObject,
            campaignAssetsObject,
            setCampaignAssetsObject,
            selectedShop,
            setSelectedShop,
            isPmaxActive,
            setIsPmaxActive,
            isCampaignCreated,
            setIsCampaignCreated,
            hasItems,
            setHasItems,
            isCampaignDeactivated,
            setIsCampaignDeactivated,
            selectedImages,
            setSelectedImages,
            selectedLogos,
            setLogos,
            headline,
            setHeadline,
            longHeadline,
            setLongHeadline,
            shortDescPart1,
            setShortDescPart1,
            shortDescPart2,
            setShortDescPart2,
            descs,
            setDescs,
            deleteAll,
            appContentType,
            setAppContentType,
            code,
            setCode,
            pno,
            setPno,
            shopName,
            setShopName,
            merchantId,
            setMerchantId,
            campaignId,
            setCampaignId,
            budget,
            setBudget,
            campaignBudget,
            setCampaignBudget
        }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppProvider