import React from "react";

const PrivacyPolicy = () => (
  <div className="privacy-policy">
    <h2>Privacy Policy</h2>
    <ul>
      <li>We collect your information only with your consent; we only collect the minimum amount of information that is necessary to operate our software and services; we don’t sell or share your information with anyone else. We don’t use your data for generalized AI and ML models.</li>
      <li>At PermoniQ, we respect the client’s privacy and take protecting it seriously. The use of information collected through our service shall be limited to the purpose of providing the service for which our Clients have engaged us.</li>
      <li>We do not retain user data obtained through Google Workspace APIs to develop, improve, or train non-personalized AI and/or ML models. We do not use your data for generalized machine-learning (ML) or artificial intelligence (AI) models.</li>
      <li>Google sign-in will authenticate your identity and provide you the option to share certain personal information with us such as your email address. The information you provide is held in strict confidence. We collect this information to generate your user license, send payment receipts and communicate with the user about product updates.</li>
      <li>We may also send you service-related email announcements on rare occasions when it is necessary to do so. For instance, we may send out notifications regarding service-related issues such as maintenance periods, billing problems, and other items that may impact service.</li>
      <li>Our services use the Google OAuth 2.0 protocol for authentication without requiring the user to share their login credentials with us. OAuth also allows secure access to the user’s data, as required by the add-ons, and the data resides strictly inside your Google account. We do not store any of your account data on our servers.</li>
      <li>If your personal information changes, or if you no longer desire our service, you may delete it by contacting us. We will respond to your request within 5 business days.</li>
      <li>We reserve the right to disclose your personal information as required by law, such as to comply with a subpoena, bankruptcy proceedings or similar legal process, and when we believe that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud and/or to comply with a judicial proceeding, court order, or legal process served on our website.</li>
      <li>PermoniQ’s use and transfer of information received from Google APIs to any other app will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements.</li>
    </ul>
  </div>
);

export default PrivacyPolicy;
