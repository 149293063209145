import React from 'react';

const GoogleTermsPage = () => {
  return (
    <section className="google-api-usage-disclosure">
      <h1>Google API Services Usage Disclosure</h1>
      <p>
        Permoniq's use of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google API Services User Data Policy </a>Google API Services User Data Policy, including the Limited Use requirements.
      </p>

      <h2>Limited Use</h2>
      <p>
        Our app strictly complies with all conditions specified in the limited use policy of Google.
      </p>
      <ul>
        <li>Do not use the user data to train non-personalized AI and/or ML models. Transfers of data for generalized machine-learning (ML) or artificial intelligence (AI) models are prohibited.</li>
        <li>Do not allow humans to read the user's data unless you have obtained the user's affirmative agreement to view specific messages, files, or other data.</li>
        <li>Limit your use of data to providing or improving user-facing features that are prominent in the requesting application's user interface. All other uses of the data are prohibited;</li>
        <li>Only transfer the data to others if necessary to provide or improve user-facing features that are prominent in the requesting application's user interface.</li>
      </ul>
    </section>
  );
};

export default GoogleTermsPage;
