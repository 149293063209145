import i18n from "i18next"
import { initReactI18next} from "react-i18next"
import HttpApi from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"

// const getLanguageFromURL = () => {
//     const params = new URLSearchParams(window.location.pathname);
//     return params.get('lang') || 'cs'; // Výchozí jazyk je 'en'
//   };

i18n.use(HttpApi).use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: "cs",
    detection: {
        order: ['path', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
        caches: ["cookie"],
    },
    backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    react: {
        useSuspense: false
    }
})

export default i18n