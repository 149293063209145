import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from '../img/logo-inverse.svg';
import { ReactSVG } from 'react-svg';
import phoneLogo from "../img/icons/phone.svg";
import loginLogo from "../img/icons/login.svg";

import csFlag from "../img/flags/cs.png";
import enFlag from "../img/flags/en.png";
import skFlag from "../img/flags/sk.png";
import plFlag from "../img/flags/pl.png";
import huFlag from "../img/flags/hu.png";
import roFlag from "../img/flags/ro.png";

const flags = {
  cs: csFlag,
  en: enFlag,
  sk: skFlag,
  pl: plFlag,
  hu: huFlag,
  ro: roFlag,
};

const NavBar = () => {
    const { t, i18n } = useTranslation();
    const { lng } = useParams();
    const navigate = useNavigate();
    const isLogged = useSelector((state) => state.login.isLogged);
    const [isLanguageSwitchVisible, setIsLanguageSwitchVisible] = useState(false);
    const languageRef = useRef(null);
    const [selectedLanguage, setSelectedLanguage] = useState(lng || 'cs');

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLogoClick = () => {
        window.location.href = `/${lng}/`;
    };

    const handleLoginClick = (e) => {
        e.preventDefault();
        window.scrollTo(0, 0);
        navigate(isLogged ? `/${lng}/app/campaigns` : `/${lng}/login`, { replace: true });
    };

    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language.toLowerCase());
        setSelectedLanguage(language.toLowerCase());
        setIsLanguageSwitchVisible(false);
        navigate(`/${language.toLowerCase()}/`);
    };

    const toggleLanguageSwitch = (e) => {
        e.preventDefault();
        setIsLanguageSwitchVisible(!isLanguageSwitchVisible);
    };

    const handleClickOutside = (event) => {
        if (languageRef.current && !languageRef.current.contains(event.target)) {
            setIsLanguageSwitchVisible(false);
        }
    };

    return (
        <div className='container'>
            <div className="header__logo">
                <img
                    src={logo}
                    alt="PermoniQ"
                    width="151"
                    height="32"
                    title={t('nav.home')}
                    onClick={handleLogoClick}
                    style={{ cursor: 'pointer' }}
                />
            </div>
            <nav className='nav'>
                <ul>
                    <li><NavLink to={`/${lng}/#${t('paths.benefits')}`}>{t('nav.benefits')}</NavLink></li>
                    <li><NavLink to={`/${lng}/#${t('paths.whatItCanDo')}`}>{t('nav.whatItCanDo')}</NavLink></li>
                    <li><NavLink to={`/${lng}/#${t('paths.comparison')}`}>{t('nav.comparison')}</NavLink></li>
                    <li><NavLink to={`/${lng}/#${t('paths.forWhom')}`}>{t('nav.forWhom')}</NavLink></li>
                    <li><NavLink to={`/${lng}/#${t('paths.references')}`}>{t('nav.references')}</NavLink></li>
                    <li className='nav__cta'><NavLink to={`/${lng}/#${t('paths.tryFree')}`} className="btn">{t('nav.tryFree')}</NavLink></li>
                    <li className='nav__phone'>
                        <NavLink to={`tel:+420252666180`}>
                            <span className='icon icon--phone'>
                                <ReactSVG
                                    src={phoneLogo}
                                    className="icon__svg"
                                />
                            </span>
                            &nbsp;252 666 180
                        </NavLink>
                    </li>
                    <li className="nav__login">
                        <a href="#" onClick={handleLoginClick}>
                            <span className="icon icon--login">
                                <ReactSVG
                                    src={loginLogo}
                                    className='icon__svg' />
                            </span>
                            {isLogged ? t('nav.myAccount') : t('nav.login')}
                        </a>
                    </li>
                    <li className="nav__language" ref={languageRef}>
                        <a href="" onClick={toggleLanguageSwitch}>
                            <img src={flags[selectedLanguage]} alt={t(`loginLanguagesAppNavBar.${selectedLanguage}`)} />
                        </a>
                        <div className={`language__switch ${isLanguageSwitchVisible ? 'is-visible' : ''}`}>
                            {Object.keys(flags).map((lang) => (
                                <a href="" onClick={() => handleLanguageChange(lang.toLowerCase())} key={lang}>
                                    <img src={flags[lang.toLowerCase()]} alt={t(`loginLanguagesAppNavBar.${lang.toLowerCase()}`)} /> {t(`loginLanguagesAppNavBar.${lang.toLowerCase()}`)}
                                </a>
                            ))}
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default NavBar;
