import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../components/AxiosConfig";

export const getUserData = createAsyncThunk(
    "user/getUserData",
    async () => {
        try {
            const response = await axiosInstance.get('user/detail/');

            const shops = response.data.shops;
            let shop = {};

            if (shops && window.location.pathname.includes("register")) {
                shop = shops.find(oneShop => oneShop.status_create === "step1");
            } else if (shops) {
                shop = shops.find(oneShop => oneShop.status_create === "end");
            }

            // Return an object containing both the shop and the full response data
            return {
                shop: shop || {}, // Provide a default empty object if shop is undefined
                data: response.data
            };
        } catch (error) {
            return Promise.reject(error.response.data);
        }
    }
);
