import React from 'react';
import styles from './LoadingModal.module.css'; // Import CSS modulu
import Loading from './Loading';

const LoadingModal = () => {
  return (
    <div className={styles.loadingModalOverlay}>
      <div className={styles.loadingModal}>
        <Loading />
      </div>
    </div>
  );
};

export default LoadingModal;
