import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  url: '',
  phone: '',
  name: '',
  surname: '',
  country: 'cs',
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    updateForm: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    setFormData: (state, action) => {
      return { ...state, ...action.payload };
    }
  }
});

export const { updateForm, setFormData } = formSlice.actions;
export default formSlice.reducer;
