import React from 'react';
import dangerIcon from '../img/icons/danger.svg';
import googleIcon from '../img/login-google.svg';
import userIcon from '../img/icons/user.svg';
import basketIcon from '../img/icons/basket.svg';
import documentIcon from '../img/icons/document.svg';
import productsIcon from '../img/icons/products.svg';
import indicatorIcon from '../img/icons/indicator.svg';
import growIcon from '../img/icons/grow.svg';
import clickIcon from '../img/icons/click.svg';
import turnonIcon from '../img/icons/turnon.svg';
import thumbIcon from '../img/icons/thumb.svg';
import feedIcon from '../img/icons/feed.svg';
import clockIcon from '../img/icons/clock.svg';

const Dashboard = () => {
  return (
    <article className="app-content app-content--dashboard">
      <div className="item item--danger">
        <span className="item__ico">
          <span className="icon icon--danger">
            <img src={dangerIcon} alt="danger" className="icon__svg" />
          </span>
        </span>
        <span className="item__title">Váš účet je neaktivní, prosím dobijte si kredit.</span>
        <span className="item__control">
          <a href="#" className="btn">Dobít kredit</a>
        </span>
      </div>

      <div className="box">
        <h2>Začínáme s PermoniQem</h2>
        <div className="progress progress--app">
          <div className="container">
            <ul>
              <li><strong>1.</strong> Zaregistrujte svůj e-shop</li>
              <li><strong>2.</strong> Proveďte základní nastavení</li>
              <li className="is-active"><strong>3.</strong> Dobijte si kredit</li>
              <li className="is-inactive"><strong>4.</strong> Spusťte kampaně</li>
            </ul>
          </div>
        </div>

        <div className="box__note">
          <h3>Dobijte si kredit</h3>
          <p>
            Dobijte si do své virtuální peněženky reklamní kredit, ze kterého se financují reklamní kampaně.<br />
            Na začátek doporučujeme dobít alespoň 10 000,00 Kč, aby kampaně získaly dostatek dat na optimalizace a doručily maximální výkon.
          </p>
        </div>
      </div>

      <div className="app-content__row">
        <div className="box">
          <h2>Kampaně</h2>
          <div className="box__note">
            <p>Přehled vašich kampaní v PermoniQ</p>
          </div>
          <h4>Zapnuté reklamní formáty</h4>
          <div className="campaign">
            <span className="campaign__ico"><img src={googleIcon} alt="google" /></span>
            <span className="campaign__title">Google shopping (PLA)</span>
          </div>
          <div className="campaign">
            <span className="campaign__ico"><img src={googleIcon} alt="google" /></span>
            <span className="campaign__title">Dynamické textové reklamy (DSA)</span>
          </div>
          <h4>Doporučujeme také zapnout</h4>
          <div className="campaign campaign--on">
            <span className="campaign__ico"><img src={googleIcon} alt="google" /></span>
            <span className="campaign__title">Performance max</span>
          </div>
          <br />
          <div className="campaign campaign--on">
            <span className="campaign__ico"><img src={googleIcon} alt="google" /></span>
            <span className="campaign__title">Performance max</span>
          </div>
        </div>

        <div className="box">
          <h2>Učící fáze kampaní</h2>
          <div className="box__note box__note--bubble">
            <h3>Co je to učící fáze kampaní?</h3>
            <p>Pro maximální výkon je potřeba získat alespoň 30 objednávek za posledních 30 dní.</p>
          </div>
          <div className="campaign campaign--status">
            <span className="campaign__ico"><img src={googleIcon} alt="google" /></span>
            <span className="campaign__title">Google shopping (PLA)</span>
            <span className="campaign__status is-partial"><em>1</em></span>
          </div>
          <div className="campaign campaign--status">
            <span className="campaign__ico"><img src={googleIcon} alt="google" /></span>
            <span className="campaign__title">Performance max</span>
            <span className="campaign__status"><em>0</em></span>
          </div>
          <div className="campaign campaign--status">
            <span className="campaign__ico"><img src={googleIcon} alt="google" /></span>
            <span className="campaign__title">Dynamické textové reklamy</span>
            <span className="campaign__status is-full"><em>30</em></span>
          </div>
          <div className="campaign campaign--status">
            <span className="campaign__ico"><img src={googleIcon} alt="google" /></span>
            <span className="campaign__title">Dynamické produktové reklamy</span>
            <span className="campaign__status is-partial"><em>15</em></span>
          </div>
        </div>
      </div>

      <div className="box">
        <h2>Kondice měření <small>Jednotlivé události jsou kontrolovány za posledních 48 hodin.</small></h2>
        <ul className="indicator">
          <li className="indicator__item">
            <span className="icon icon--user">
              <img src={userIcon} alt="user" className="icon__svg" />
            </span>
            Uživatelé
            <span className="icon icon--indicator">
              <img src={indicatorIcon} alt="indicator" className="icon__svg" />
            </span>
          </li>
          <li className="indicator__item is-success">
            <span className="icon icon--basket">
              <img src={basketIcon} alt="basket" className="icon__svg" />
            </span>
            Přidání do košíku
            <span className="icon icon--indicator">
              <img src={indicatorIcon} alt="indicator" className="icon__svg" />
            </span>
          </li>
          <li className="indicator__item is-success">
            <span className="icon icon--document">
              <img src={documentIcon} alt="document" className="icon__svg" />
            </span>
            Objednávky
            <span className="icon icon--indicator">
              <img src={indicatorIcon} alt="indicator" className="icon__svg" />
            </span>
          </li>
          <li className="indicator__item is-danger">
            <span className="icon icon--products">
              <img src={productsIcon} alt="products" className="icon__svg" />
            </span>
            Prodané produkty
            <span className="icon icon--indicator">
              <img src={indicatorIcon} alt="indicator" className="icon__svg" />
            </span>
          </li>
        </ul>
      </div>

      <div className="box">
        <h2>Základní statistiky <small>Za posledních 30 dní</small></h2>
        <div className="row">
          <div className="col col--2">
            <p className="graph">
              <span className="graph__legend">
                <em><span className="graph__point graph__point--1"></span> Výdaje</em>
                <em><span className="graph__point graph__point--3"></span> Obrat</em>
              </span>
            </p>
            <p>
              <img src="/img/placeholder/graph-4.png" alt="graph" />
            </p>
          </div>
          <div className="col col--2">
            <div className="info info--7">
              <span className="pack pack--7">
                <span className="icon icon--basket">
                  <img src={basketIcon} alt="basket" className="icon__svg" />
                </span>
              </span>
              <span className="info__title">Počet objednávek</span>
              <span className="info__value">25</span>
            </div>
            <br />
            <div className="info info--5">
              <span className="pack pack--5">
                <span className="icon icon--grow">
                  <img src={growIcon} alt="grow" className="icon__svg" />
                </span>
              </span>
              <span className="info__title">PNO</span>
              <span className="info__value">25,5 %</span>
            </div>
            <br />
            <div className="info info--6">
              <span className="pack pack--6">
                <span className="icon icon--click">
                  <img src={clickIcon} alt="click" className="icon__svg" />
                </span>
              </span>
              <span className="info__title">Počet kliknutí</span>
              <span className="info__value">6 654</span>
            </div>
          </div>
        </div>
      </div>

      <div className="app-content__row">
        <div className="box">
          <h2>Jak zvýšit výkon kampaní?</h2>
          <div className="box__note">
            <p>Pro maximální výkon kampaní dodržujte následující základní pravidla.</p>
          </div>
          <ul className="box__list">
            <li>
              <span className="icon icon--turnon">
                <img src={turnonIcon} alt="turnon" className="icon__svg" />
              </span>
              Udržujte kampaně zapnuté pro sběr dat. Kampaně, které jsou dlouho vypnuté, ztrácí data pro optimalizaci.
            </li>
            <li>
              <span className="icon icon--thumb">
                <img src={thumbIcon} alt="thumb" className="icon__svg" />
              </span>
              Spusťte si doporučené reklamní formáty a nahrajte kvalitní podklady pro reklamy.
            </li>
            <li>
              <span className="icon icon--feed">
                <img src={feedIcon} alt="feed" className="icon__svg" />
              </span>
              Produkty v XML feedu musí mít kvalitní a odpovídající názvy a popisy, čitelné obrázky a všechny povinné atributy.
            </li>
            <li>
              <span className="icon icon--clock">
                <img src={clockIcon} alt="clock" className="icon__svg" />
              </span>
              Dejte kampaním čas. Požadovaný výkon se dostaví ve chvíli, kdy mají kampaně dostatek dat. To může v některých případech trvat i několik měsíců.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2>Jak klesá PNI v čase?</h2>
          <div className="box__note">
            <p><strong>PNO = Podíl nákladů na obratu.</strong></p>
            <p>Čím více dat mají kampaně k dispozici, tím se také zlepšuje jejich výkonnost. podívejte se na následující graf, jak e-shopům průměrně klesá PNO v prvních 12 měsících od prvního spuštění kampaní.</p>
          </div>
          <br />
          <p>
            <img src="/img/placeholder/graph-5.png" alt="graph" />
          </p>
        </div>
      </div>

      <div className="box">
        <h2>Přehled konkurenčních metrik <small>Data se aktualizují jednou za měsíc</small></h2>
        <div className="box__note">
          <p>Tento report obsahuje přehled nejdůležitějších metrik a jejich průměrných hodnot na několika tisíc e-shopů. Data v tomto reportu jsou anonymizována a slouží jen pro obecný přehled. Tato data můžete použít pro své porovnání, jak si stojíte v konkurenci s ostatními a případně najít slabá místa.</p>
        </div>
        <ul className="metrics">
          <li><strong>PNO</strong>14,23 %</li>
          <li><strong>Konverzní poměr</strong>3,23 %</li>
          <li><strong>Hodnota objednávky</strong>1 250,00 Kč</li>
          <li><strong>Průměrné denní výdaje</strong>843,00 Kč</li>
          <li><strong>Průměrné denní tržby</strong>5 924,00 Kč</li>
        </ul>
      </div>

      <p><a href="#" className="link">Jak se měří statistiky v PermoniQ?</a></p>
    </article>
  );
};

export default Dashboard;
